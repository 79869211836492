<template>
  <div>
    <el-row style="margin-top:10px;">
      <el-button type="primary" v-if="checkPermission('admin.course.add')" @click="addExercise">添加题目</el-button>
    </el-row>
    <el-row style="margin-top:10px;">
      <el-table :data="exerciseList" border stripe>
        <el-table-column label="题目编号" prop="id"></el-table-column>
        <el-table-column label="题目标题" prop="title" ></el-table-column>
        <el-table-column label="题目类型"  prop="type">
          <template slot-scope="scope" >
            <span  v-if="scope.row.type ==1">单选</span>
            <span v-else>多选</span>
          </template>
        </el-table-column>
        <el-table-column label="答案数量" prop="num"></el-table-column>
        <el-table-column label="答案类型" prop="option_type">
           <template slot-scope="scope" >
            <span  v-if="scope.row.option_type ==1">文本</span>
            <span v-else-if="scope.row.option_type ==2">图片</span>
            <span v-else-if="scope.row.option_type ==3">图文结合</span>
          </template>
        </el-table-column>
        <el-table-column label="状态" prop="satus">
          <template slot-scope="scope" >
              <span  v-if="scope.row.status ==1">启用</span>
              <span v-else>禁用</span>
          </template>
        </el-table-column>
        <el-table-column label="排序" prop="sort"></el-table-column>
        <el-table-column label="操作" width="240px">
          <template slot-scope="scope" >
          <el-button type="text" size="mini" v-if="checkPermission('admin.course.edit')" @click="editExercise(scope.row)" >编辑</el-button>
          <template >
            <el-button type="text" v-if="scope.row.status == 2" size="mini" @click="changeExerciseStatus(scope.row)">启用</el-button>
            <el-button type="text" v-else-if="scope.row.status == 1" size="mini" @click="changeExerciseStatus(scope.row)">禁用</el-button>
          </template>
          <el-popconfirm title="确定要删除嘛?" @onConfirm="delClick(scope.row.id)" @confirm="delClick(scope.row.id)">
            <el-button  type="text" slot="reference" size="mini" >删除</el-button>
          </el-popconfirm>
          </template>
        </el-table-column>
      </el-table>
    </el-row>

    <!--添加题目start-->
    <el-dialog title="添加题目" :close-on-click-modal="false" :visible.sync="addDialogVisible" width="60%" @close="addDialogClosed">
      <el-form ref="addFormRef" :model="addForm"> 
        <!-- :inline="true" -->
        <el-form-item label="题目:" prop="title">
          <el-input v-model="addForm.title" placeholder="* 题目字数最多45个字" style="margin-left:28px;width:80%" maxlength="45"></el-input>
        </el-form-item>   
        <el-form-item label="题目语音:" >
          <el-upload
              class="avatar-uploader"
              action="#"
              :show-file-list="false"
              :http-request="(file)=> {uploadFile(file,0,0)}"
              >
            <div v-if="addForm.audio" style="width:178px;height:35px">{{ addForm.audio | removeDir |hidden}}</div>
            <div v-else style="width:178px;height:35px">上传</div>
          </el-upload>
          <el-progress  v-if="show[0]" :percentage="percentage[0]" class="line"></el-progress>
        </el-form-item>
        <el-form-item label="题目顺序:">
          <el-input v-model="addForm.sort" type="number"  style="width:80%"></el-input>
        </el-form-item>
        <el-form-item label="题目类型:">
          <template>
            <el-radio v-model="addForm.ex_type" label="1" >单选题</el-radio>
            <el-radio v-model="addForm.ex_type" label="2" >多选题</el-radio>
          </template>
        </el-form-item> 
        <el-form-item label="答案数量:">
          <el-select v-model="addForm.answerNum" placeholder="请选择" @change="selectAnswerNum(addForm.answerNum)">
            <!-- @change="selectAnswerNum(num)" -->
            <el-option value="2">2</el-option>
            <el-option value="3">3</el-option>
            <el-option value="4">4</el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="答案类型:">
          <el-select v-model="addForm.answerType" placeholder="请选择" @change="selectAnswerType(addForm.answerType)">
            <el-option value="1" label="文字">文字</el-option>
            <el-option value="2" label="图片">图片</el-option>
            <el-option value="3" label="图文结合">图文结合</el-option>
          </el-select>
        </el-form-item>     
        <el-form-item>
        <div>答案设置:</div>
        <div >说明：需配置并勾选正确答案，<span style="color:red">图文结合文字</span>答案最多12个字，<span style="color:red">纯文字</span>答案最多20个字，<span style="color:red">图片</span>答案最佳尺寸为138x94px</div>

        <!--单选-->
        <div v-if="addForm.ex_type == 1">  
          <div v-if="addForm.answerType == 1"> 
            <div v-for="index of num1" :key="index">
              <el-row >
                <el-col :span="2">
                  <el-radio v-model="addForm.answerRadio" :label="index" >答案{{index | tranNum}}</el-radio>
                </el-col>
                <el-col :span="12">
                  <el-input v-model="addForm.answerRadioText[index-1]"  type="text"  style="width:96%" maxlength="20"></el-input>
                </el-col>
                <el-col :span="5">
                  <el-form-item>
                    <el-upload
                        class="avatar-uploader"
                        action="#"
                        :show-file-list="false"
                        :http-request="(file)=> {uploadFile(file,1,index)}"
                        >
                      <div v-if="addForm.answerRadioAudio[index-1]">
                          <div v-if="addForm.answerRadioAudio[index-1].url" style="width:178px;height:35px">{{ addForm.answerRadioAudio[index-1].url | removeDir| hidden }}</div>
                        <div v-else style="width:178px;height:35px">上传</div>
                      </div>
                    </el-upload>
                  </el-form-item>
                </el-col>
              </el-row>
            </div>
          </div>
          <div v-else-if="addForm.answerType == 2">
            <!-- 图片 -->
              <div v-for="index of num1" :key="index">
                <el-row>
                  <el-col :span="2">
                    <el-radio  :label="index" v-model="addForm.answerRadio" >答案{{index | tranNum}}</el-radio>
                  </el-col>
                  <el-col :span="8" style="margin:3px 0;">
                    <el-form-item >
                      <el-upload
                        class="avatar-uploader"
                        action="#"
                        :show-file-list="false"
                        style="width:130px;height:130px"
                        :http-request="(file)=> {uploadFile(file,2,index)}"                
                      >
                      <div v-if="addForm.answerRadioImg[index-1]">
                        <img
                          v-if="addForm.answerRadioImg[index - 1].url"
                          :src="imgDomain + addForm.answerRadioImg[index - 1].url"
                          class="avatar"
                          style="width:130px;height:130px"
                        />
                        <i v-else class="el-icon-plus avatar-uploader-icon" style="width:130px;height:130px"></i>
                        <!-- <i  class="el-icon-plus avatar-uploader-icon" ></i> -->
                      </div>
                      </el-upload>
                    </el-form-item>
                  </el-col>
                </el-row>
              </div>
            </div>
            <div v-else-if="addForm.answerType == 3">
            <!-- 图文结合 -->
              <div v-for="index of num1" :key="index" style="margin:5px 0">
                <el-row style="line-height:136px;">
                  <el-col :span="2">
                    <el-radio  :label="index" v-model="addForm.answerRadio" >答案{{index | tranNum}}</el-radio>
                  </el-col>
                  <el-col :span="8">
                    <el-input v-model="addForm.answerRadioText[index-1]"  type="text"  style="width:96%" maxlength="12"></el-input>
                  </el-col>
                  <div style="line-height:136px;margin-top:48px;">
                    <el-col :span="5">
                      <el-form-item >
                        <el-upload
                            class="avatar-uploader"
                            action="#"
                            :show-file-list="false"
                            :http-request="(file)=> {uploadFile(file,1,index)}"
                            >
                          <div v-if="addForm.answerRadioAudio[index-1]">
                              <div v-if="addForm.answerRadioAudio[index-1].url" style="width:178px;height:35px">{{ addForm.answerRadioAudio[index-1].url | removeDir| hidden }}</div>
                            <div v-else style="width:178px;height:35px">上传</div>
                          </div>
                        </el-upload>
                      </el-form-item>
                    </el-col>
                  </div>
                  <el-col :span="5" style="margin-top:-30px;">
                    <el-form-item >
                      <el-upload
                        class="avatar-uploader"
                        action="#"
                        :show-file-list="false"
                        style="width:130px;height:130px;"
                        :http-request="(file)=> {uploadFile(file,2,index)}"                
                      >
                      <div v-if="addForm.answerRadioImg[index-1]">
                        <img
                          v-if="addForm.answerRadioImg[index - 1].url"
                          :src="imgDomain + addForm.answerRadioImg[index - 1].url"
                          class="avatar"
                          style="width:130px;height:130px"
                        />
                        <i v-else class="el-icon-plus avatar-uploader-icon" style="width:130px;height:130px"></i>
                        <!-- <i  class="el-icon-plus avatar-uploader-icon" ></i> -->
                      </div>
                      </el-upload>
                    </el-form-item>
                  </el-col>
                </el-row>
              </div>
            </div>
        </div>
        <!--单选end-->
        <!--多选start-->
        <div v-else>
            <div v-if="addForm.answerType == 1">
            <div v-for="index of num1" :key="index" >
              <el-row>
                <el-col :span="2" >
                  <el-checkbox :label="index" v-model="addForm.answerCheckbox[index-1]">答案{{index | tranNum}}</el-checkbox>
                </el-col>
                <el-col :span="12">
                  <el-input v-model="addForm.answerRadioText[index-1]" type="text"  style="width:96%" maxlength="20"></el-input>
                </el-col>
                <el-col :span="5">
                  <el-form-item >
                    <el-upload
                        class="avatar-uploader"
                        action="#"
                        :show-file-list="false"
                        :http-request="(file)=> {uploadFile(file,1,index)}"
                        >
                      <div v-if="addForm.answerRadioAudio[index-1]">
                        <div v-if="addForm.answerRadioAudio[index-1].url" style="width:178px;height:35px">{{ addForm.answerRadioAudio[index-1].url | removeDir|hidden }}</div>
                        <div v-else style="width:178px;height:35px">上传</div>
                      </div>
                    </el-upload>
                  </el-form-item>
                </el-col>
              </el-row>
            </div>
          </div>
          <div v-else-if="addForm.answerType == 2">
            <!-- 图片 -->
              <div v-for="index of num1" :key="index">
                <el-row>
                  <el-col :span="2">
                    <el-checkbox :label="index" v-model="addForm.answerCheckbox[index-1]">答案{{index | tranNum}}</el-checkbox>
                  </el-col>
                  <el-col :span="8">
                    <el-form-item>
                      <el-upload
                        class="avatar-uploader"
                        action="#"
                        :show-file-list="false"
                        style="width:130px;height:130px"
               
                        :http-request="(file)=> {uploadFile(file,2,index)}"              
                      >
                      <div v-if="addForm.answerRadioImg[index-1]">
                        <img
                          v-if="addForm.answerRadioImg[index - 1].url"
                          :src="imgDomain + addForm.answerRadioImg[index - 1].url"
                          class="avatar"
                          style="width:130px;height:130px"
                        />
                        <i v-else class="el-icon-plus avatar-uploader-icon" style="width:130px;height:130px"></i>
                      </div>
                      </el-upload>
                    </el-form-item>
                  </el-col>
                </el-row>
              </div>
            </div>
            <!--图文结合-->
            <div v-else-if="addForm.answerType == 3">
            <!-- 图片 -->
              <div v-for="index of num1" :key="index">
                <el-row style="line-height:136px;">
                  <el-col :span="2">
                    <el-checkbox :label="index" v-model="addForm.answerCheckbox[index-1]">答案{{index | tranNum}}</el-checkbox>
                  </el-col>
                  <el-col :span="8">
                    <el-input v-model="addForm.answerRadioText[index-1]" type="text"  style="width:96%" maxlength="12"></el-input>
                  </el-col>
                  <div style="line-height:136px;margin-top:48px;">
                  <el-col :span="5">
                    <el-form-item >
                      <el-upload
                          class="avatar-uploader"
                          action="#"
                          :show-file-list="false"
                          :http-request="(file)=> {uploadFile(file,1,index)}"
                          >
                        <div v-if="addForm.answerRadioAudio[index-1]">
                          <div v-if="addForm.answerRadioAudio[index-1].url" style="width:178px;height:35px">{{ addForm.answerRadioAudio[index-1].url | removeDir|hidden }}</div>
                          <div v-else style="width:178px;height:35px">上传</div>
                        </div>
                      </el-upload>
                    </el-form-item>
                  </el-col>
                  </div>
                  <el-col :span="5" style="margin-top:-30px;">
                    <el-form-item>
                      <el-upload
                        class="avatar-uploader"
                        action="#"
                        :show-file-list="false"
                        style="width:130px;height:130px"
                        :http-request="(file)=> {uploadFile(file,2,index)}"              
                      >
                      <div v-if="addForm.answerRadioImg[index-1]">
                        <img
                          v-if="addForm.answerRadioImg[index - 1].url"
                          :src="imgDomain + addForm.answerRadioImg[index - 1].url"
                          class="avatar"
                          style="width:130px;height:130px"
                        />
                        <i v-else class="el-icon-plus avatar-uploader-icon" style="width:130px;height:130px"></i>
                      </div>
                      </el-upload>
                    </el-form-item>
                  </el-col>
                </el-row>
              </div>
            </div>
          </div>
          <!--多选end-->          
        </el-form-item>  
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addDialogVisible = false">取消</el-button>
        <el-button type="primary" @click="addSubmit">确定</el-button>
      </span>
    </el-dialog>
    <!--添加课程end-->
  </div>
</template>

<script>
import { contentAdd ,contentDetail, changeExerciseStatus} from '@/api/course'
import {getQiniuToken} from '@/api/upload'
import * as qiniu from 'qiniu-js'
export default {
 data() {
  return { 
    courseId: this.$route.query.id,
    exerciseList: [],
    pagenum: 1,
    pagesize: 10,
    total: 0,
    gameType: 3,
    addDialogVisible: false,
    addForm: {
      id: -1,
      title: '',
      audio: '',
      sort: 100,
      ex_type: '1',
      answerNum: '' ,
      answerType: '1',
      answerRadio: '',
      answerRadioText: [],
      answerRadioAudio: [],
      answerRadioImg: [],
      answerCheckbox: [false,false,false,false], //[true,false,false,true]  false,false,false,false]
    },
    num1: '',
    is_edit: 0,
    temp: '',
    //
    show: [false,false],
    percentage: [0,0],
    selectType:"",
  }
 },
 created(){
   this.getExerciseList()
   this.changeFatherFlag()
 },
 filters: {
  removeDir(value) {
    let site = value.lastIndexOf("\/");
      return value.substring(site+1, value.length)
  },
  hidden(value){
    let end = value.lastIndexOf("-")
    if(end>20){
      end = 20
    }
    return value.substring(0, end)
  },
  tranNum(value) {
    if (value == 1){
      return 'A'
    }else if(value ==2){
      return 'B'
    }else if(value ==3){
      return 'C'
    }else if(value ==4){
      return 'D'
    }
  },
  
},
 methods: {
  // ----------改变父组件的值----------- 
   changeFatherFlag(){
     this.$emit('flagChange', false)
   },
  //-----------分页----------------
  handleSizeChange(){

  },
  handleCurrentChange(){

  },
  addExercise(){
    this.clear()
    this.addDialogVisible = true
  },
  // 修改
  editExercise(row){
    console.log(row)
    this.addForm.title = row.title
    this.addForm.audio = row.audio
    this.addForm.sort = row.sort
    this.addForm.ex_type = row.type.toString()  //题目类型
    this.addForm.answerType = row.option_type.toString() //答案类型
    this.addForm.answerNum = row.num
    this.selectAnswerNum(row.num)
    this.addForm.id = row.id
    this.selectType = row.option_type.toString()
 
    for(let i=0;i<row.num;i++){
      //文本
      if(row.text[i] == ''){
        this.addForm.answerRadioText[i] = ''
      }else{
        if(this.addForm.answerType == 1 || this.addForm.answerType == 3){
          this.addForm.answerRadioText[i] = row.text[i]
        }else{
          this.addForm.answerRadioText[i] = ''
        }
      }

      if(row.type == 1){
        if(row.check[i] == 'true'){
          this.addForm.answerRadio = i+1
        }
      }else{
        if(row.check[i] == 'false'){
          this.addForm.answerCheckbox[i] = false
        }else{
          this.addForm.answerCheckbox[i] = true
        }
      }

      // 音频
      if(row.audioList[i] == ''){
        this.addForm.answerRadioAudio[i] = {}
      }else{
        if(this.addForm.answerType == 1  || this.addForm.answerType == 3){
          this.addForm.answerRadioAudio[i] = {url:row.audioList[i]}
        }else{
          this.addForm.answerRadioAudio[i] = {}
        }
        
      }

      // 图片
      if(row.img[i] == ''){
        this.addForm.answerRadioImg[i] = {}
      }else{
        if(this.addForm.answerType == 2 || this.addForm.answerType == 3 ){
          this.addForm.answerRadioImg[i] = {url:row.img[i]}
        }else{
          this.addForm.answerRadioImg[i] = {}
        }    
      }
      
    } 
    this.is_edit = 1
    this.addForm.id = row.id
    this.addDialogVisible = true
  },
  //-------------------修改状态-----------------
   async changeExerciseStatus(row){
    const status = row.status ==1 ? 2: 1
    const {data:res} = await changeExerciseStatus({games_id:row.id, status: status})
    if(res.status != 0){
      return this.$message.error(res.msg)
    }
    this.$message.success('状态修改成功')
    this.getExerciseList()
  },
  //-------------题目列表----------------
  async getExerciseList(){
    const {data:res} = await contentDetail({
      courseId: this.$route.query.id,
      game_type: this.gameType,
    })
    this.exerciseList = res.data
  },
  //-----------------------------
  showEditDialog(){

  },
  //---------删除----------------
  async delClick(id){
    const {data:res} = await changeExerciseStatus({games_id:id, status: 3})
    if(res.status != 0){
      return this.$message.error(res.msg)
    }
    this.$message.success('删除成功')
    this.getExerciseList()
  },
  //------------文件上传------------------
  async uploadFile(uploadInfo,type,index){
    const {data:token} = await getQiniuToken()
    const that = this
    const observer = {
        next(res){
          
        },
        error(err){
          console.log(err)
        },
        complete(res){
          if(type == 0){ // 题目语音
            that.addForm.audio = res.key
          }else if(type == 1){ //1.单选音频
            that.$set( that.addForm.answerRadioAudio,index-1, {url:res.key})
          }else if(type == 2){ // 2.单选图片
            that.$set( that.addForm.answerRadioImg,index-1, {url:res.key})
          }
        }
    }
    
     let name = uploadInfo.file.name
      let fileName = this.getFileName(name)
      if(parseInt(fileName) !=NaN){
        name = fileName+"-"+this.randStr(10)+"."+this.getExt(name)
      }

    const observable =  qiniu.upload(uploadInfo.file, "interactcourse/"+ name, token,{},{})
    const subscription =  observable.subscribe(observer)
  }, 
   randStr(e){
      let str = "abacdefghijklmn123456789QWERTYUIOPASDFGHJKLZXCVBNM"
      let n = "";
      for(let i=0;i<e;i++){
        n += str.charAt(Math.floor(Math.random()*str.length))
      }
      return n
    },
    getFileName(val){
      let end = val.lastIndexOf(".")
      return val.substring(0,end)
    },
    getExt(val){
      return val.substring(val.lastIndexOf(".")+1)
    },
  //----------答案数量---------------
  selectAnswerNum(num){
    this.num1 = parseInt(this.addForm.answerNum)
    if(this.addForm.ex_type == 1){
      this.addForm.answerRadio = ''
    }
 
    let testLen = this.addForm.answerRadioText.length
    if( testLen == 0){
      this.addForm.answerRadioText = []
      for(let i=0;i<this.num1;i++){
        this.addForm.answerRadioText.push('')
      }
    }else{
      if(num > testLen){
        for(let i=0;i<num-testLen;i++){
          this.addForm.answerRadioText.push('')
        }
      }
    }

    let audioLen = this.addForm.answerRadioAudio.length
    if( testLen == 0){
      this.addForm.answerRadioAudio = []
      for(let i=0;i<this.num1;i++){
        this.addForm.answerRadioAudio.push({})
      }
    }else{
      if(num > audioLen){
        for(let i=0;i<num-testLen;i++){
          this.addForm.answerRadioAudio.push({})
        }
      }
    }


    let imgLen = this.addForm.answerRadioImg.length
    if( imgLen == 0){
      this.addForm.answerRadioImg = []
      for(let i=0;i<this.num1;i++){
        this.addForm.answerRadioImg.push({})
      }
    }else{
      if(num > imgLen){
        for(let i=0;i<num-imgLen;i++){
          this.addForm.answerRadioImg.push({})
        }
      }
    }   
  },
  selectAnswerType(type){
    console.log("type:",type)
    if(this.temp == ''){
      this.temp = this.addForm.answerRadio
      this.addForm.answerRadio = ''
    }else{      
      this.addForm.answerRadio = this.temp
      this.temp = ''
    }
  
    this.addForm.answerRadioText = []
    this.addForm.answerRadioAudio = []
    this.addForm.answerRadioImg = []
    for(let i=0;i<this.addForm.answerNum;i++){
        this.addForm.answerRadioAudio.push({})
        this.addForm.answerRadioText.push('')
        this.addForm.answerRadioImg.push({})
    } 
    
    this.addForm.answerRadio = ""
    this.addForm.answerCheckbox =  [false,false,false,false]
   

    this.selectType = type
  },
  //----------------
  addDialogClosed(){
    this.$refs.addFormRef.resetFields() 
    this.clear()
  },
  clear(){
    this.addForm.title = ''
    this.addForm.audio = ''
    this.addForm.sort = 100
    this.num1 = ''
    this.addForm.ex_type = '1' 
    this.addForm.answerRadio  = ''
    this.addForm.answerRadioAudio = [] 
    this.addForm.answerRadioText = []
    this.addForm.answerRadioImg = []
    this.addForm.answerCheckbox =  [false,false,false,false]
    this.addForm.answerNum = ''
    this.addForm.id = -1
    this.is_edit = 0
  },
  //-------------------------
  async addSubmit(){
    if(this.checkForm()!=1){
      return
    }
    this.addForm.courseId = this.courseId
    this.addForm.game_type = this.gameType

    

    // 答案数量切换  (大 减小  清除多余的)
    if(this.addForm.answerNum<this.addForm.answerRadioImg.length || this.addForm.answerNum<this.addForm.answerRadioText.length){
      while(this.addForm.answerRadioImg.length-this.addForm.answerNum){
        this.addForm.answerRadioImg.pop()
        this.addForm.answerRadioText.pop()
        this.addForm.answerRadioAudio.pop()
        this.addForm.answerCheckbox.pop()
      }
    }

    const {data:res} = await contentAdd(this.addForm)
     if(res.status!=0){
      return this.$message.error(res.msg)
    }
    this.$message.success(res.msg)
    this.getExerciseList()
    this.addDialogVisible = false
  },
  checkForm(){
    if(this.addForm.title == '' ){
      return this.$message.error({'message':'请输入题目',duration:1000})
    }
    if(this.addForm.audio == ""){
      return this.$message.error({'message':'请上传题目语音',duration:1000})
    }
    if(this.addForm.sort == ""){
      return this.$message.error({'message':'题目排序值不能为空',duration:1000})
    }
    if(this.addForm.answerNum == ""){
      return this.$message.error({'message':'答案数量必选',duration:1000})
    }
    if(this.addForm.answerType == ""){
      return this.$message.error({'message':'答案类型必选',duration:1000})
    }

    if(this.addForm.ex_type ==1 ){
      if(this.addForm.answerRadio == ""){
        return this.$message.error({'message':'请勾选正确答案',duration:1000})
      }
    }

    if(this.addForm.ex_type == 2){
      if(this.addForm.answerCheckbox.length<1){
        return this.$message.error({'message':'请勾选正确答案!!',duration:1000})
      }
    }

    if(this.addForm.answerType== 1){ //文本
      for(let i=0;i<this.num1;i++){
        // 答案文本
        if(this.addForm.answerRadioText[i] == ""){
          return this.$message.error({'message':'请填写答案选项内容',duration:1000})
        }

        // 音频
        if(!this.addForm.answerRadioAudio[i].url){
          return this.$message.error({'message':'请上传音频',duration:1000})
        }else{
          if(this.addForm.answerRadioAudio[i].url == ""){
            return this.$message.error({'message':'请上传音频',duration:1000})
          }
        }
      }
    }else if(this.addForm.answerType== 2){   // 图片
        for(let i=0;i<this.num1;i++){ 
          if(this.addForm.answerRadioImg[i].url == undefined || this.addForm.answerRadioImg[i].url == ""){
            return this.$message.error({'message':'请上传答案图片',duration:1000})
          }
        }
    }else if(this.addForm.answerType== 3){
      for(let i=0;i<this.num1;i++){
        // 答案文本
        if(this.addForm.answerRadioText[i] == ""){
          return this.$message.error({'message':'请填写答案选项内容',duration:1000})
        }

        // 音频
        if(!this.addForm.answerRadioAudio[i].url){
          return this.$message.error({'message':'请上传音频',duration:1000})
        }else{
          if(this.addForm.answerRadioAudio[i].url == ""){
            return this.$message.error({'message':'请上传音频',duration:1000})
          }
        }

        // 图片
        if(this.addForm.answerRadioImg[i].url == undefined || this.addForm.answerRadioImg[i].url == ""){
          return this.$message.error({'message':'请上传答案图片',duration:1000})
        }
      }
    }     
    
    return 1

  }
 }   
}
</script>

<style scoped>
.el-icon-plus:before {
  line-height: 130px;
}
</style>