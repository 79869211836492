import request from '@/utils/request'

// 文件上传
export  function imgUpload(params) {
  const formData = new FormData();
  formData.append("file", params.file); // 文件内容
  const header = {
    "Content-Type": "mutipart/form-data",
    'Token': window.sessionStorage.getItem("token")
  }
  return request({
    url: 'admim/upload/upload',
    method: 'post',
    headers: header,
    data:formData  
  })
}


export function getQiniuToken(data){ 
  return  request({
      url: 'admin/upload/getQiniuToken',
      method: 'post',
      data
    })
}