<!--消息记录-->
<template>
<div>
  <el-card>
    <el-row style="margin-top:10px;">
      <el-table :data="list" 
        border stripe 
        :row-style="{height:'50px'}" 
        :cell-style='cellStyle' 
        :header-cell-style='rowClass'
        >
        <el-table-column label="ID" prop="id"></el-table-column>
        <el-table-column label="手机号" prop="mobile_phone"></el-table-column>
        <el-table-column label="发送时间" prop="send_time"></el-table-column>
      </el-table>
    </el-row>

    <!--分页start-->
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="search.pagenum"
      :page-sizes="[20, 40, 70, 100]"
      :page-size="search.pagesize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total">
    </el-pagination>
    <!--分页end-->

  </el-card>
</div>
</template>

<script>
import {sendRecords} from '@/api/message'
export default {
 data (){
  return {
    list:[],
    total: 0,
    search: {
      pagenum: 1,
      pagesize: 20,
      mes_template_id:this.$route.query.message_template_id
    },
  }
 },
 created(){
    this.getList()
 },
 methods: {
  //-----------------------------tabel 文字居中-------------------------------
  rowClass({row, rowIndex}){
    return 'text-align:center'
  },
  cellStyle({row, column, rowIndex, columnIndex}){
    return 'text-align:center;padding:0px' 
  },
  //-------------------------------列表-----------------------------------
  async getList(){
    const {data:res} = await sendRecords(this.search)
    if(res.status!=0){
      return this.$message.error(res.msg)
    }
    this.list = res.data.data
    this.total = res.data.total
  },
  // -------------------------分页-------------------------------------------
  handleSizeChange(newSize) {
    this.search.pagesize = newSize
    this.getList()
  },
  handleCurrentChange(newPage) {
    this.search.pagenum = newPage
    this.getList()
  },
  //------------------------------筛选---------------------------------------
  
  //-----------------------------------
 }
}
</script>