import request from '@/utils/request'

//--------------日历-------------------

export function list(data) {
  return request({
    url: '/admin/calendar/list',
    method: 'post',
    data
  })
}

export function changeStatus(data) {
  return request({
    url: '/admin/calendar/changeStatus',
    method: 'post',
    timeout: 60* 3 * 1000,
    data
  })
}