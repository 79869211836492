<template>
  <el-container>
    <!--左侧-->
    <el-aside :width="isCollapse?'64px':'200px'">
      <!--logo-->
      <div style='height:50px;background-color:skyblue;line-height:50px;text-align:center;' v-if="logo">
        <img src="" alt="">
        <span style="font-size:20px;">后台管理系统</span>
      </div>
      <el-menu background-color="#545c64" text-color="#fff" active-text-color="#ffd04b"
      unique-opened
      :collapse="isCollapse"
      :collapse-transition="false"
      :router="true" 
      :default-active="routeName"
      >
        <!--一级菜单start-->
        <el-submenu :index="first.id + ''" v-for="first in menulist" :key="first.id">
        <!-- <el-submenu index="1" > -->
          <template slot="title">
            <i :class="first.icon"></i>
            <span>{{first.name}}</span>
          </template>
          <!--二级菜单start-->
          <template v-if="first.children[0].children[0]">
            <el-submenu   :index="'/' + second.route_name" v-for="second in first.children" :key="second.id">
            <template slot="title">
              <i :class="second.icon"></i>
              <span>{{second.name}}</span>
            </template>
            <!--三级菜单start-->
            <el-menu-item  :index="'/' + third.route_name" v-for="third in second.children" :key="third.id">
              <template slot="title">
                <i :class="third.icon"></i>
                <span>{{third.name}}</span>
              </template>
            </el-menu-item>
            <!--三级菜单end-->
            </el-submenu>
          </template>
          <template v-else>
            <el-menu-item  :index="'/' + second.route_name" v-for="second in first.children" :key="second.id">
            <template slot="title">
              <i :class="second.icon"></i>
              <span>{{second.name}}</span>
            </template>
            </el-menu-item>
          </template>
          
          <!--二级菜单end-->
        </el-submenu>
        <!--一级菜单end-->
      </el-menu>
    </el-aside>
    <!--右-->
    <el-container>
      <el-header style="">
        <div class="toggle-button" @click="toggleCollapse"><i class="el-icon-s-fold" style="font-size: 30px"></i></div>
        <div style="float:left;">
          <div style="float:left;height:40px; vertical-align:middle;line-height:40px;margin-right:10px">你好,
            {{username}}</div>
          <el-button type="info" @click="logout">退出</el-button>
        </div>
      </el-header>
      <el-main>
        <router-view></router-view>
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
import { userMenuAuth } from '@/api/user'
export default {
  data() {
    return {
      username:window.sessionStorage.getItem('username'),
      logo: true,
      menulist: [],
      isCollapse: false,
      routeName: this.$route.path
    }
  },
  created() {
    this.getMenuList()
  }, // 菜单
  methods: {
    async getMenuList() {
      const { data: ret } = await userMenuAuth()
      this.menulist = ret.data.menu
      window.sessionStorage.setItem('notMenuAuth',ret.data.notMenu)
    },
    toggleCollapse() {
      this.isCollapse = !this.isCollapse
    },
    logout() {
      window.sessionStorage.clear()
      this.$router.push('/login')
    }
  }
}
</script>

<style lang="scss" scoped>
.el-container{
  height: 100%;
  .el-aside{
    background-color:#304156 ;
    .el-menu {
      border-right: none;
    }
  }
}
.el-header{
  display: flex;
  justify-content:space-between;
  align-items: center;
}
.toggle-button {
  cursor: pointer;
}
</style>
