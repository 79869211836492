<template>
  <div class="u-div">
    <el-form label-width="100px">
      <el-form-item :label="title">  
        <el-upload 
          class="avatar-uploader" 
          action="#" 
          :show-file-list="false" 
          :http-request="(file) => {uploadFile(file,1)}"
          :class="[uploadClass]"
        >
          <template v-if="gate=='image'">
             <img v-if="url" :src="imgDomain+url" class="avatar">
             <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </template>
          <template v-else-if="gate=='audio'">
            <i class="el-icon-plus avatar-uploader-icon" style="height: 40px; width: 200px">{{url }}</i>
          </template>
        </el-upload>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import {getQiniuToken} from '@/api/upload'
import * as qiniu from 'qiniu-js'
export default {
  props:{
    title:{
      type:String
    },
    type:{ // 上传文件类型
      type:String
    },
    // src:{
    //   type:String
    // },
    // size:{ 
    //   type:Number
    // },
    
  },
  computed:{
    size(){
      switch(`${this.type}` ){
        case "img":
          return this.img * 1024 * 1024
        case "video":
          return this.video * 1024 * 1024
        case "audio":
          return this.audio * 1024 * 1024
        default: 
          return 1024*1024*1024
      }  
    },
    src(){
      return ""
    }
  },
  filters: {
    removeDir(value) {
      let site = value.lastIndexOf("\/");
      return value.substring(site+1, value.length)
    },
    hidden(value){
      let end = value.lastIndexOf("-")
      if(end>20){
        end = 20
      }
      return value.substring(0, end)
    },
  },
  data() {
    return {
      qiniu: "https://qiniu.hafeikids.com/",
      img:1,
      video:100,
      audio:10,
      url:"",
      uploadClass:"uploadClass",
      gate:"image",
    }
  },
  methods:{
    async uploadFile(file,index){
      let retStr = this.limit(file)
      if(retStr != ""){
        return this.$message.error(retStr)
      }      

      const {data:token} =  await getQiniuToken()
      const that = this
      const observer = {
          next(res){
            console.log('next:', res)
          },
          error(err){
            console.log(err)
          },
          complete(res){
            console.log('complete:....')
            that.url = res.key

            that.$emit("getUrl",res.key) // 子传父
          }
        }
 
        let name = file.file.name 
        name = this.randStr(12) +"."+ this.getExt(name)
        const observable =  qiniu.upload(file.file, "interactcourse/" + name, token,{},{})
        const subscription =  observable.subscribe(observer)
    },
    limit(val){
      let type = val.file.type
      let size = val.file.size
      let type_arr = type.split("/")
      this.gate = type_arr[0]
      switch(type_arr[0]){
        case "image":
          if(size > this.size){
            return "上传图片需小于"+ this.img +"M"
          }
          break
        case "audio":
          if(size > this.size){
            return "上传音频需小于"+ this.audio +"M"
          }
          break
        case "video":
          if(size > this.size){
            return "上传视频需小于"+ this.video +"M"
          }
          break
      } 
      return ""
    },
    randStr(e){
      let str = "abacdefghijklmn123456789QWERTYUIOPASDFGHJKLZXCVBNMzxcvbnmqwertyuiopasdfghjkl"
      let n = "";
      for(let i=0;i<e;i++){
        n += str.charAt(Math.floor(Math.random()*str.length))
      }
      return n
    },
    getFileName(val){
      let end = val.lastIndexOf(".")
      return val.substring(0,end)
    },
    getExt(val){
      return val.substring(val.lastIndexOf(".")+1)
    },
  }

}
</script>

<style scoped>
.u-div{
  width:178px;
  height: 178px;
}
.uploadClass{
  /* position:absolute; */
  margin-top: 30px;
}
</style>