<!--课表管理-->
<template>
  <div>
    <el-card>
      <div class="nav_1">
        <div style="float:left;width:400px;">
          <div style="float:left;margin-right:5px;height:40px;line-height:40px;">课程包名称: </div>
          <div style="float:left;width:320px;">
            <el-select v-model="cp_select" placeholder="请选择" @change="selectBag(cp_select)" filterable style="width:100%">
              <el-option value="">--置空--</el-option>
              <el-option v-for="(item, index) in cpList" :key="index" :label="item.course_bag_name" :value="item.id">{{
                  item.course_bag_name
              }}
              </el-option>
            </el-select>
          </div>
        </div>
        <!--日期start-->
        <div style="float:left;margin-left:15px;height:40px;line-height:40px;">开课时间: </div>
        <div style="float:left;margin-left:20px;">
          <div class="block">
            <el-date-picker v-model="value1" type="daterange" range-separator="至" start-placeholder="开始日期"
              @blur="timeBlur" value-format="yyyy-MM-dd" end-placeholder="结束日期" >
              <!-- :picker-options="pickerOptions" -->
            </el-date-picker>
          </div>
        </div>
        <div  style="float:left;margin-left:20px;">
          <el-button  type="primary" icon="el-icon-search" @click="btnClick" >搜索</el-button>
          <el-button  type="warning" icon="el-icon-refresh" @click="reset" >重置</el-button>
        </div>
      </div>
      <!--日期end-->
      <div class="clear"></div>
      <el-row style="margin-top:10px;">
        <el-button type="primary" @click="addcp"> 添加课表</el-button>
        <el-button type="success" @click="excelExport">Excel导出</el-button>
      </el-row>
      <el-row style="margin-top:10px;">
        <el-table :data="courseTime" border stripe :row-style="{ height: '45px' }" :cell-style="{ padding: '0px' }">
          <el-table-column label="课表编号" prop="course_bag_time_no"></el-table-column>
          <el-table-column label="课表名称" prop="course_bag_time_name"></el-table-column>
          <el-table-column label="课程包名称" prop="course_bag_name"></el-table-column>
          <el-table-column label="开课时间" prop="course_bag_open_time"></el-table-column>
          <el-table-column label="负责客服" prop="name"></el-table-column>
          <el-table-column label="报名人数" prop="user_sign_up_count"></el-table-column>
          <el-table-column label="状态" prop="status_name"> </el-table-column>
          <el-table-column label="操作" width="280px">
            <template slot-scope="scope">
              <el-button type="text" size="mini" @click="editCp(scope.row)"
                v-if="scope.row.course_bag_time_status == 1">编辑</el-button>
              <el-button type="text" size="mini" @click="advanceKK(scope.row)"
                v-if="scope.row.course_bag_time_status == 1">提前开课</el-button>
              <el-button type="text" size="mini" @click="jumpUserInfo(scope.row)">报名信息</el-button>
              <!-- <el-popconfirm title="确定要删除嘛?" @onConfirm="delTimeTable(scope.row.id)" @confirm="delTimeTable(scope.row.id)"> -->
                <el-button type="text" slot="reference" size="mini" v-if="scope.row.course_bag_time_status == 1 && scope.row.user_sign_up_count ==0"
                  style="margin-left:5px" @click="delTimeTable(scope.row.id)">删除</el-button>
              <!-- </el-popconfirm> -->
            </template>
          </el-table-column>
         
        </el-table>

        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
            :current-page="search.pagenum" :page-sizes="[10, 20, 30, 50]" :page-size="search.pagesize"
            layout="total, sizes, prev, pager, next, jumper" :total="total">
          </el-pagination>

        <!--添加-->
        <el-dialog title="添加课表" :close-on-click-modal="false" :visible.sync="addDialogVisible" width="28%" @close="addDialogClosed" >
          <el-form label-width="100px">
            <el-form-item label="课表名称">
              <el-input v-model="courseTimeData.time_name"></el-input>
            </el-form-item>
            <el-form-item label="选择课程包">
              <el-select v-model="courseTimeData.course_bag_id" placeholder="请选择" :disabled="disabled">
                <el-option v-for="(item, index) in cpList" :key="index" :label="item.course_bag_name"
                  :value="item.id" />
              </el-select>
            </el-form-item>
            <el-form-item label="开课时间">
              <div class="block">
                <el-date-picker value-format="yyyy-MM-dd" format="yyyy-MM-dd" v-model="courseTimeData.open_time"
                  type="date" placeholder="选择日期" >
                </el-date-picker>
              </div>
            </el-form-item>
            <el-form-item label="选择客服">
              <el-select v-model="courseTimeData.customer_id" placeholder="请选择">
                <el-option v-for="(item, index) in kefuList" :key="index" :label="item.name"
                  :value="item.id" />
              </el-select>
            </el-form-item>
          </el-form>
          <span slot="footer" class="dialog-footer">
            <el-button @click="addDialogVisible = false">取消</el-button>
            <el-button type="primary" @click="submitCourseSchoolTime()">确定</el-button>
          </span>
        </el-dialog>
        <!--添加-->

      </el-row>
    </el-card>
  </div>
</template>


<script>
import { list, addCourseTime, updateCourseTime, advanceCourseTime, deleteTimeTable } from '@/api/courseSchoolTime'
import { getCourseBagAll } from '@/api/coursePackage'
import { getTimeCustomerList } from '@/api/customer'
export default {
  data() {
    return {
      courseTimeOperator: "",
      courseTime: [],
      cpList: [],
      cp_select: [],
      kefuList: [],
      total:0,
      disabled: false,
      courseTimeData: {
        course_bag_time_id: 0,
        course_bag_id: "",
        time_name: "",
        open_time: "",
        customer_id:""
      },
      //---------------搜索------------------
      search: {
        course_bag_id: '',
        start_date: '',
        end_date: '',
        pagenum: 1,
        pagesize: 10,
      },
      //---------------时间--------------------
      pickerOptions: {
        disabledDate(time) {
            return (time.getDay() === 6) || (time.getDay() === 0)
          },
        shortcuts: [{
          text: '最近一周',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近一个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近三个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit('pick', [start, end]);
          }
        }]
      },
      value1: [],
      //-----------------------------------
      addDialogVisible: false,
      pickerOptions0: {
        disabledDate(time) {
          return time.getTime() < Date.now() - 8.64e7;//如果没有后面的-8.64e7就是不可以选择今天的 
        }
      },
    }
  },
  //  watch: {
  //   value1(newData) {
  //     if(newData == null){
  //       this.value1 = []
  //       this.search.start_date = ''
  //       this.search.end_date = ''
  //     }  
  //     this.getCourseTimeList(this.search)
  //   }
  // },
  created() {
    this.getCoursePackageAll({course_bag_status:3});
    if(this.$route.query.search!=undefined && this.$route.query.search!= "[object Object]"){
      this.search = this.$route.query.search
      this.cp_select = this.search.course_bag_id
      this.value1[0] = this.search.start_date
      this.value1[1] = this.search.end_date
    }
    this.getCourseTimeList(this.search);
  },
  methods: {
     // -------------------------分页----------------------------------
     handleSizeChange(newSize) {
      this.search.pagesize = newSize
      this.getCourseTimeList(this.search)
    },
    handleCurrentChange(newPage) {
      this.search.pagenum = newPage
      this.getCourseTimeList(this.search)
    },
    editCp(row) {
      this.courseTimeData.course_bag_time_id = row.id;
      this.courseTimeData.course_bag_id = row.course_bag_id;
      this.courseTimeData.time_name = row.course_bag_time_name;
      this.courseTimeData.open_time = row.course_bag_open_time;
      this.courseTimeOperator = "edit";
      this.addDialogVisible = true;
      this.getKefuList()
      if(row.user_sign_up_count>0){
        this.disabled = true
      }
      this.courseTimeData.customer_id = row.customer_id
    },
    addcp() { 
      this.getKefuList()
      this.getCoursePackageAll({course_bag_status:1})
      this.courseTimeData.course_bag_time_id = 0;
      this.courseTimeData.course_bag_id = "";
      this.courseTimeData.time_name = "";
      this.courseTimeData.open_time = "";
      this.addDialogVisible = true
      this.courseTimeOperator = "add";
    },
    addDialogClosed(){
      this.cpList =[ ]
      this.getCoursePackageAll({})
      this.disabled = false
      this.courseTimeData.customer_id = ""
    },
    //-------------删除------------
    confirm() {
      this.visible = false;
      this.$emit('onConfirm');
    },
    async delTimeTable(id) {

      this.$confirm('确认要删除嘛', '提示', {
        confirmButtonText: '确认',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        const { data: res } = await deleteTimeTable({ course_bag_time_id: id })
        if (res.status != 0) {
          return this.$message.error(res.msg)
        }
        this.getCourseTimeList()
        this.$message.success('删除成功')
      }).catch(()=>{
        console.log("课表取消删除...")
      }) 
    },
    //----------------------------跳转: 用户报名信息-----------------------------
    jumpUserInfo(row) {
      this.$router.push({ path: '/admin.course.userSignUp', query: { bagTimeId: row.id ,search:this.search} })
    },
    //------------------------------搜索---------------
    selectBag(value) {
      this.search.course_bag_id = value
      this.search.pagenum = 1
      this.getCourseTimeList({course_bag_status:3})
    },
    timeBlur() {
      this.search.start_date = this.value1[0]
      this.search.end_date = this.value1[1]
    },
    btnClick(){
       this.getCourseTimeList()
    },
    reset(){
     this.value1 = []
     this.search.start_date = ""
     this.search.end_date = ""
     this.search.course_bag_id = ""
     this.cp_select = []
     this.getCourseTimeList()
    },
    //-----------------------------导出--------------------------
    excelExport() {
      const headers = {
        '课表编号': 'course_bag_time_no',
        '课表名称': 'course_bag_time_name',
        '课程包名称': 'course_bag_name',
        '开课时间': 'course_bag_open_time',
        '报名人数': 'user_sign_up_count',
      }

      import('@/vendor/Export2Excel').then(async excel => {
        const { data: res } = await list(this.search)
        const data = this.formatJson(headers, res.data.data)
        excel.export_json_to_excel({
          header: Object.keys(headers),
          data,
          filename: '课表信息',
          autoWidth: true,
          bookType: 'xlsx'
        })
      })
    },
    formatJson(headers, rows) {
      return rows.map(item => {
        return Object.keys(headers).map(key => {
          return item[headers[key]]
        })
      })
    },
    //------------------------客服--------
    async getKefuList(){
      const {data:res} = await getTimeCustomerList({customer_type:1,status:1})
      if (res.status != 0) {
        return this.$message.error(res.msg)
      }
      this.kefuList = res.data
    },
    //------------------------------------------------
    async getCourseTimeList(query) {
      const { data: res } = await list(this.search)
      if (res.status != 0) {
        return this.$message.error(res.msg)
      }
      this.courseTime = res.data.data
      this.total = res.data.total
    },
    async getCoursePackageAll(condition) {
      const { data: res } = await getCourseBagAll(condition)
      if (res.status != 0) {
        return this.$message.error(res.msg)
      }
      this.cpList = res.data
    },
    async submitCourseSchoolTime() {
      const self = this;
      let courseApiData = {};
      if (this.courseTimeData.course_bag_id == 0) {
        this.$message.error("请选择课程包")
        return false;
      } else {
        courseApiData.course_bag_id = this.courseTimeData.course_bag_id
      }
      if (this.courseTimeData.time_name == "") {
        this.$message.error("请输入课表名称")
        return false;
      } else {
        courseApiData.time_name = this.courseTimeData.time_name
      }
      if (this.courseTimeData.open_time == "") {
        this.$message.error("请选择开课时间")
        return false;
      } else {
        courseApiData.open_time = this.courseTimeData.open_time
      }

      if (this.courseTimeData.customer_id == "") {
        this.$message.error("请选择客服")
        return false;
      } else {
        courseApiData.customer_id = this.courseTimeData.customer_id
      }

      if (this.courseTimeOperator == "add") {
        addCourseTime(courseApiData).then((res) => {
          let code = res.data.status;
          if (code === 0) {
            self.$message.success("添加课表成功")
            self.addDialogVisible = false;
            self.getCourseTimeList({})
          } else {
            self.$message.error(res.data.msg)
          }
        })
      } else if (this.courseTimeOperator == "edit") {
        if (this.courseTimeData.course_bag_time_id) {
          courseApiData.course_bag_time_id = this.courseTimeData.course_bag_time_id;
          updateCourseTime(courseApiData).then((res) => {
            let code = res.data.status;
            if (code === 0) {
              self.$message.success("更新课表成功")
              self.addDialogVisible = false;
              self.getCourseTimeList({})
              self.courseTimeOperator = "";
            } else {
              self.$message.error(res.data.msg)
            }
          })
        }
      }
    },
    async advanceKK(row) {
      const self = this;
      let title = "当前课程开课时间为" + row.course_bag_open_time + "，若提前开课，已购买用户将可立即上课，是否提前开课？";
      this.$confirm(title, '提示', {
        confirmButtonText: '确认提前',
        cancelButtonText: '暂不提前',
        type: 'warning'
      }).then(() => {
        let advanceApi = {};
        advanceApi.course_bag_time_id = row.id;
        advanceCourseTime(advanceApi).then((res) => {
          let code = res.data.status;
          if (code === 0) {
            self.$message.success("提前开课成功")
            self.addDialogVisible = false;
            self.getCourseTimeList(self.search)
            self.courseTimeOperator = "";
          } else {
            self.$message.error(res.data.msg)
          }
        })
      }).catch(() => {
      });
    }
  }
}
</script>

<style scoped>
.nav_1 {
  margin-bottom: 10px;
  float: left;
}
</style>