<template>
  <div class="calendar-header clear">
    <div class="calendar-box" v-if="this.headOptions.type == 'combination'">
      <div class="select">
        <div class="select-l">
          年份:
          <el-select v-model="select_year" placeholder="请选择" @change="changeYear(select_year)">
            <el-option
              v-for="(item,index) in year" :key="index"
              :label="item"
              :value="item"
              >
            </el-option>
          </el-select>
        </div>
        <div class="select-r">
          月份:
          <el-select v-model="select_month" placeholder="请选择" @change="changeMonth(select_month)">
            <el-option
              v-for="(item,index) in month" :key="index"
              :label="item"
              :value="item"
              >
            </el-option>
          </el-select>
        </div>   
      </div>   
    </div>
    <div class="calendar-box" v-else>
      <span class="calendar-headDate"> {{this.headOptions.date}} </span>
      <span class="calendar-today dispersion-today" :style="{float: this.headStyle.todayBtn}" @click="handleToday()"> 今天 </span>
      <div class="calendar-content dispersion" :style="{float: this.headStyle.checkBtn}">
        <span class="calendar-prev" @click="handlePrevMonth"></span>
        <span class="calendar-next" @click="handleNextMonth"></span>
      </div>
     
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      headOptions: Object
    },
    data () {
      return {
        headStyle: '',
        month: [1,2,3,4,5,6,7,8,9,10,11,12],
        year:[2022,2023,2024,2025,2026,2027,2028,2029,2030,2031],
        select_year:'',
        select_month:'',
      }
    },
    mounted() {
      let arr = this.headOptions.date.split('/')
      this.select_month = arr[0]
      this.select_year = arr[1]    
    },
    methods: {
      // 生成10年

      // 改变年
      changeYear(year) {
        this.select_year = year
        this.$emit('changeYear',[year,this.select_month])
      },
      // 改变月
      changeMonth(month){
        this.select_month = month
        this.$emit('changeMonth',[this.select_year,month])
      },
      // 回到今天
      handleToday () {
        this.$emit('handleToday');
      }
    }
  }
</script>

<style lang="less">
  .calendar-header {
    margin-bottom: 23px;
    width: 70%;
    .calendar-box {
      position: relative;
      height: 32px;
      line-height: 32px;
      .calendar-content {
        width: 100%;
        .calendar-prev,
        .calendar-next {
          display: inline-block;
          vertical-align: middle;
          width: 8px;
          height: 11px;
          background: url('@/assets/img/left.png') no-repeat;
          background-size: contain;
          cursor: pointer;
        }
        .calendar-next {
          background: url('@/assets/img/right.png') no-repeat;
          background-size: contain;
        }
      }
      .dispersion{
        width: initial;
        display: inline;
      }
      .calendar-headDate {
        vertical-align: middle;
        margin: 0 12px;
        font-size: 18px;
        color: #424953;
        -webkit-user-select: none;
        -webkit-touch-callout: none; 
        -moz-user-select: none;
        -ms-user-select: none; 
        user-select: none;
      }
      .calendar-today {
        position: absolute;
        top: 0;
        right: 0;
        width: 80px;
        height: 25px;
        text-align: center;
        border: 1px solid #2061FF;
        border-radius: 4px;
        font-size: 14px;
        color: #caf982;
        cursor: pointer;
      }
      .dispersion-today{
        position: inherit;
      }
      .calendar-center {
        margin: 0 auto;
      }
      .calendar-left {
        float: left;
      }
      .calendar-right {
        float: right;
      }
    }
  }
  .select{
    float: left;
    .select-l{
      float: left;
    }
    .select-r{
      float:left;
      margin-left: 20px;
    }
  }
</style>