<!--消息管理-->
<template>
<div>
  <el-card>
    <div class="nav_1">
      <div class="div-left">
        <div class="d-order-no">消息标题: </div>
        <div  class="div-left">
          <el-input class="input" v-model="search.keyword"></el-input>
        </div>
        <div class="div-left">
          <el-button  type="primary" icon="el-icon-search" @click="btnClick" >搜索</el-button>
        </div>
      </div>
    </div>
    <!---->
    <div class="clear"></div>
    <el-row style="margin-top:10px;">
      <el-button type="success" style="width:100px;" @click="excelExport">Excel导出</el-button>
    </el-row>
    <el-row style="margin-top:10px;">
      <el-table :data="list" 
        border stripe 
        :row-style="{height:'50px'}" 
        :cell-style='cellStyle' 
        :header-cell-style='rowClass'
        >
        <el-table-column label="消息ID" prop="id"></el-table-column>
        <el-table-column label="消息分类" prop="">
          <template slot-scope="scope">
            <span v-if="scope.row.message_template_id == 1">首次注册</span>
            <span v-else-if="scope.row.message_template_id == 2">订单支付成功</span>
            <span v-else-if="scope.row.message_template_id == 3">订单退款/退课成功</span>
          </template>
        </el-table-column>
        <el-table-column label="消息标题" prop="message_title"></el-table-column>
        <el-table-column label="消息内容" prop="message_content"></el-table-column>
        <el-table-column label="发送方式" prop="send_type_string">
        </el-table-column>
        <el-table-column label="已发次数" prop="send_records_count"></el-table-column>
         <el-table-column label="操作" width="240px">
            <template slot-scope="scope">
              <!-- <el-button type="text" size="mini" >编辑</el-button> -->
              <el-button type="text"  size="mini" v-if="scope.row.status ==2" @click="changeSta(scope.row.id,scope.row.status)">启用</el-button>
              <el-button type="text"  size="mini" v-else @click="changeSta(scope.row.id,scope.row.status)">禁用</el-button>
              <el-button type="text" size="mini" disabled>发送消息</el-button>
              <el-button type="text" size="mini" @click="jump(scope.row.message_template_id)">发送记录</el-button>            
            </template>
          </el-table-column>
      </el-table>
    </el-row>

    <!--分页start-->
    <!-- <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="search.pagenum"
      :page-sizes="[10, 20, 30, 50]"
      :page-size="search.pagesize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total">
    </el-pagination> -->
    <!--分页end-->

  </el-card>
</div>
</template>

<script>
import {list,changeStatus} from '@/api/message'
export default {
 data (){
  return {
    list:[],
    total: 0,
    search: {
      keyword: ''
    },
    addDialogVisible: false,
  }
 },
 created(){
  this.getList()
 },
 methods: {
  //-----------------------------tabel 文字居中-------------------------------
  rowClass({row, rowIndex}){
    return 'text-align:center'
  },
  cellStyle({row, column, rowIndex, columnIndex}){
    return 'text-align:center;padding:0px' 
  },
  //-------------------------------订单列表-----------------------------------
  async getList(){
    const {data:res} = await list(this.search)
    if(res.status!=0){
      return this.$message.error(res.msg)
    }
    this.list = res.data
  },
  // -------------------------分页-------------------------------------------
  handleSizeChange(newSize) {
    this.search.pagesize = newSize
    this.getOrderList(this.search)
  },
  handleCurrentChange(newPage) {
    this.search.pagenum = newPage
    this.getOrderList(this.search)
  },
  //
  async changeSta(id,status){
    const {data:res} = await changeStatus({mes_id:id,status:status})
    if(res.status!=0){
      return this.$message.error(res.msg)
    }
    this.$message.success('ok')
    this.getList()
  },
  //------------------------------筛选---------------------------------------
  btnClick(){
    this.getList()
  },
  //---------发送记录
  jump(id){
    this.$router.push({ path: '/admin.messageRecords', query: { message_template_id:id} })
  },
  //-----------------------------excelExport------
  excelExport(){
      const headers = {
        '消息ID': 'id',
        '消息分类': 'message_template_id',
        '消息标题': 'message_title',
        '消息内容': 'message_content',
        '发送方式': 'send_type_string',
        '已发次数': 'send_records_count',
      }

      import('@/vendor/Export2Excel').then(async excel => {
        const {data:res} = await list(this.search)
        const data = this.formatJson(headers,res.data)
        excel.export_json_to_excel({
          header: Object.keys(headers), 
          data, 
          filename: '发送消息', 
          autoWidth: true, 
          bookType: 'xlsx' 
        })
      })
    },
    formatJson(headers, rows) {
    return rows.map(item => {
      return Object.keys(headers).map(key => {
        if(headers[key]=="message_template_id"){
          switch (item[headers[key]]){
            case 1:
              return "首次注册"
              break
            case 2:
              return "订单支付成功"
            case 3:
              return "订单退款/退课成功"  
          }
        }
        return item[headers[key]]
      })
    })
  },

 }
}
</script>


<style scoped>
.nav_1{
  margin-bottom: 10px;
  float:left;
}
.div-left{
  float:left;
  margin-right: 10px;
}

.input{
  width: 220px;
}

.d-order-no{
  float:left;
  margin-right:15px;
  height:35px;
  line-height:35px;
}

</style>