<!--用户:购买:上课记录-->
<template>
<div>
  <el-card>
    <div class="clear"></div>
    <el-row style="margin-top:10px;">
        <!-- <el-button type="danger" style="width:100px;" @click="back">返回</el-button> -->
      </el-row>
    <el-row style="margin-top:10px;" v-if="course_bags_cate == 1">
      <el-table :data="list" 
        border stripe 
        :row-style="{height:'50px'}" 
        :cell-style='cellStyle' 
        :header-cell-style='rowClass'
        >
        <el-table-column label="课程顺序" prop="course_number"></el-table-column>
        <el-table-column label="课程名称" prop="course_title"></el-table-column>
        <el-table-column label="翻书挑战进度" prop="game1"></el-table-column>
        <el-table-column label="趣味阅读进度" prop="game2"></el-table-column>
        <el-table-column label="阅读闯关进度" prop="game3"></el-table-column>
        <el-table-column label="自信表达进度" prop="game4"></el-table-column>
        <el-table-column label="上课进度" >
          <template slot-scope="scope" >
            <span  v-if="scope.row.record_status ==1">未开始</span>
            <span v-else-if="scope.row.record_status==2">未完成</span>
            <span v-else-if="scope.row.record_status=3">已完成</span>
          </template>
        </el-table-column>
        <el-table-column label="课程开课时间" prop="start_time">
          <template slot-scope="scope">
            <span v-if="scope.row.start_time">{{scope.row.start_time}}</span>
            <span v-else>/</span>
          </template>
        </el-table-column>
        <el-table-column label="用户课程完成时间" prop="study_time"></el-table-column>
      </el-table>
    </el-row>
    <el-row style="margin-top:10px;" v-else-if="course_bags_cate == 2">
      <el-table :data="list" 
        border stripe 
        :row-style="{height:'50px'}" 
        :cell-style='cellStyle' 
        :header-cell-style='rowClass'
        >
        <el-table-column label="课程顺序" prop="course_number"></el-table-column>
        <el-table-column label="课程名称" prop="course_title"></el-table-column>
        <el-table-column label="开启美育课堂" prop="game1"></el-table-column>
        <el-table-column label="打开绘画之门" prop="game2"></el-table-column>
        <el-table-column label="知识问答" prop="game3"></el-table-column>
        <el-table-column label="神奇画笔变变变" prop="game4"></el-table-column>
        <el-table-column label="点评" prop="game5">
          <template slot-scope="scope">
            <span>{{scope.row.game5}}(<el-button type="text" @click="showImg(scope.row.game5_img)">查看作品</el-button>)</span>
          </template>
        </el-table-column>
        <el-table-column label="上课进度" >
          <template slot-scope="scope" >
            <span  v-if="scope.row.record_status ==1">未开始</span>
            <span v-else-if="scope.row.record_status==2">未完成</span>
            <span v-else-if="scope.row.record_status=3">已完成</span>
          </template>
        </el-table-column>
        <el-table-column label="课程开课时间" prop="start_time"></el-table-column>
        <el-table-column label="用户课程完成时间" prop="study_time"></el-table-column>
      </el-table>
    </el-row>
  </el-card>  

  <el-dialog
    title="作品"
    :visible.sync="dialogVisible"
     width="50%"
    >
    <template v-if="src">
      <el-image  :src="src"></el-image>
    </template>
    <template v-else>
      暂无作品上传
    </template>
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
    </span>
  </el-dialog>
</div>
</template>

<script>
import { timeLearnRecordsOfUser } from '@/api/courseSchoolTime'
export default {
 data (){
  return {
    bagTimeId: this.$route.query.bagTimeId,
    course_bags_cate: this.$route.query.course_bags_cate,
    list:[], 
    addForm: {
      bags_id: this.$route.query.bags_id,
      bags_times_id: this.$route.query.bagTimeId,
      app_user_id: this.$route.query.app_user_id,
      pagesize:50,
      pagenum:1,
    },
    total:0,
    src:"",
    dialogVisible:false, 
  }
 },
 created() {
    this.userLearnRecords()
 },
 methods: {
  //-----------------------------tabel 文字居中-------------------------------
   rowClass({row, rowIndex}){
    return 'text-align:center'
  },
  cellStyle({row, column, rowIndex, columnIndex}){
    return 'text-align:center;padding:0px' 
  },
  //------------------------------学习记录 列表-------------------------------
  async userLearnRecords(){
    const {data:res} = await timeLearnRecordsOfUser(this.addForm)
    if(res.status!=0){
      return this.$message.error(res.msg)
    }
    this.list =  this.formData(res.data)
    this.total = res.data.total
    
  },
  formData(data){
    const arr =[]
    for( let i in data){
       arr.push({
        app_user_id:data[i].app_user_id,
        course_id:data[i].course_id,
        course_number:"第"+data[i].course_number+"讲",
        course_title:data[i].course_title,
        game1:data[i].game1,
        game2:data[i].game2,
        game3:data[i].game3,
        game4:data[i].game4,
        game5:data[i].game5,
        game5_img:data[i].game5_img,
        record_status:data[i].record_status,
        start_time:data[i].start_time,
        study_time:data[i].study_time,    
        })
    }
    return arr
  },
  //-------------------------------------------------------------------------
  showImg(val){
    this.src = val
    this.dialogVisible = true
    
  },
  //----------------------------跳转: 报名信息-----------------------------
  back(){
    this.$router.push({ path: '/admin.course.userSignUp', query: { bagTimeId: this.bagTimeId } })
  },
  // -------------------------分页-------------------------------------------
  handleSizeChange(newSize) {
    this.addForm.pagesize = newSize
    this.userSignUpList(this.search)
  },
  handleCurrentChange(newPage) {
    this.addForm.pagenum = newPage
    this.userSignUpList(this.search)
  },
 } 
}
</script>
