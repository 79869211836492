// import store from '@/store'

// 按钮权限
export default {
  methods: {
    checkPermission(key) {
      const  notMenus  = window.sessionStorage.getItem('notMenuAuth')
      const notMenusArr = (notMenus || "").split(',')
      if (notMenusArr) {
        return notMenusArr.some(item => item === key)
      }
      return false
    },
  }
}
