<template>
  <div>
    <el-form label-width="110px">
      <el-form-item label="开放题标题" prop="">
        <el-input type="textarea" v-model="addForm.title" style="width:40%;" placeholder="* 标题字数限制为60个字" maxlength="60"></el-input>
      </el-form-item>
      <el-form-item label="开放题语音:">
        <el-upload
            class="avatar-uploader"
            action="#"
            :show-file-list="false"
            :http-request="(file)=> {uploadFile(file,0)}"
            >
          <div v-if="addForm.audio" style="width:178px;height:35px">{{ addForm.audio | removeDir | hidden }}</div>
          <div v-else style="width:178px;height:35px">上传</div>
        </el-upload>
        <el-progress  v-if="show[0]" :percentage="percentage[0]" class="line"></el-progress>
      </el-form-item>
      <div style="width:368px">
      <el-form-item label="开放题封面图:" >
        <div style="height:40px;"></div>
          <el-upload
            class="avatar-uploader"
            action="#"
            :show-file-list="false"
            :http-request="(file)=> {uploadFile(file,1)}"
            >
            <img v-if="addForm.img" :src="imgDomain+addForm.img" class="avatar">
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
          <el-progress type="circle" v-if="show[1]" :percentage="percentage[1]" class="progress"></el-progress>
          <p style="color:red;font-size:8px;height:10px;margin-top:-20px;margin-left:40px">图片大小不要超过1M</p>
      </el-form-item>
      </div>
    </el-form>
    <div class="clear"></div>
    <div style="position: relative; height: 40px">
      <el-button style="position: absolute; right: 90px" @click="back">取消</el-button>
      <el-button type="primary" style="position: absolute; right: 10px" @click="knowClick">确定</el-button>
    </div>
  </div>
</template>

<script>
import { contentAdd ,contentDetail} from '@/api/course'
import {getQiniuToken} from '@/api/upload'
import * as qiniu from 'qiniu-js'
export default {
data(){
  return{
    courseId: this.$route.query.id,
    show: [false,false],
    percentage: [0,0],
    gameType: 4,
    addForm: {
      title: '',
      audio: '',
      img: '',
    },
    //-------------------
    is_edit: 0,
    tempForm: {
      title: '',
      audio: '',
      img: ''
    }
  } 
  
},
 filters: {
  removeDir(value) { 
    let site = value.lastIndexOf("\/");
      return value.substring(site+1, value.length)
  },
  hidden(value){
    let end = value.lastIndexOf("-")
    if(end>20){
      end = 20
    }
    return value.substring(0, end)
  },
},
created(){
  this.getDetail()
  this.changeFatherFlag()
},
watch: {
  addForm: {
    handler(newName,oldName){
      if(this.is_edit == 1){
        if(newName.title != this.tempForm.title){
          window.sessionStorage.setItem('courseTab',4)
        }else{
          window.sessionStorage.setItem('courseTab',null)
        }

        if(newName.audio != this.tempForm.audio){
          window.sessionStorage.setItem('courseTab',4)
        }else{
          window.sessionStorage.setItem('courseTab',null)
        }

        if(newName.img != this.tempForm.img){
          window.sessionStorage.setItem('courseTab',4)
        }else{
          window.sessionStorage.setItem('courseTab',null)
        }
        
      } else {
        if(newName.title!=''&&newName.title!=undefined || newName.audio!=""&&newName.audio!=undefined || newName.img!=""&&newName.img!=undefined){
          window.sessionStorage.setItem("courseTab",4)
        }else{
          window.sessionStorage.setItem("courseTab",null)
        }
      }      
    },
    // immediate: true,
    deep:true
  },
},
props: {
 changeTabFlag: {
  type: Function
 }
},
methods: {
// ----------改变父组件的值----------- 
   changeFatherFlag(){
     this.$emit('flagChange', false)
   },
//------------------- 详情----------------
  async getDetail(){
    const {data:res} = await contentDetail({
      courseId: this.$route.query.id,
      game_type: this.gameType,
    })

    if (res.status != 0) {
        return this.$message.error(res.msg);
    }
   
    this.addForm.title = res.data.title
    this.addForm.audio = res.data.audio
    this.addForm.img = res.data.img
    if(res.data.img!=undefined || res.data.audio!=undefined|| res.data.title!=undefined){
      this.is_edit = 1
      this.tempForm.title = res.data.title
      this.tempForm.audio = res.data.audio
      this.tempForm.img = res.data.img
    }
 
  },
//------------文件上传-----------------------
  async uploadFile(uploadInfo,index){
    if(this.img_limit(uploadInfo) == -1){
      return this.$message.error("上传图片请小于1M")
    }
    const {data:token} = await getQiniuToken()
    const that = this
    const observer = {
        next(res){
          const percentage = parseInt(res.total.percent.toString().split(".")[0])
          if(index == 0){
            that.$set(that.percentage,0,percentage)
          }else if(index == 1){
            that.$set(that.percentage,1,percentage)
          }
        },
        error(err){
          console.log(err)
        },
        complete(res){
          if(index == 0){
            that.$set(that.show,0, false)
            that.addForm.audio = res.key
          }else if(index == 1){
            that.$set(that.show,1, false)
            that.addForm.img = res.key
          }
          window.sessionStorage.setItem('courseTab', 4)
        }
    }
   
    if(index == 0){
      this.$set(this.show,0,true)
    }else if(index == 1){
      this.$set(this.show,1,true)
    }

    let name = uploadInfo.file.name
    let fileName = this.getFileName(name)
    if(parseInt(fileName) !=NaN){
      name = fileName+"-"+this.randStr(10)+"."+this.getExt(name)
    }

    const observable =  qiniu.upload(uploadInfo.file, "interactcourse/"+name, token,{},{})
    const subscription =  observable.subscribe(observer)
  }, 
  img_limit(val){
    let type = val.file.type
    let size = val.file.size
    let type_arr = type.split("/")
    let limit = 1   // 1M
    if(type_arr[0] == "image"){
      if(size> 1024*1024*limit ){
        return -1
      }
    }
    return 1
  },

  randStr(e){
    let str = "abacdefghijklmn123456789QWERTYUIOPASDFGHJKLZXCVBNM"
    let n = "";
    for(let i=0;i<e;i++){
      n += str.charAt(Math.floor(Math.random()*str.length))
    }
    return n
  },
  getFileName(val){
    let end = val.lastIndexOf(".")
    return val.substring(0,end)
  },
  getExt(val){
    return val.substring(val.lastIndexOf(".")+1)
  },
//------------------跳转--------------------
back(){
  this.$router.push("/admin.course.index"); 
  window.sessionStorage.setItem('courseTab',null)
},
//-----------------提交---------------------
async knowClick(){
  if(this.addForm.title==""){
    return this.$message.error('标题必填')
  }
  if(this.addForm.audio == ""){
    return this.$message.error('请上传语音')
  }
  if(this.addForm.img == ""){
    return this.$message.error('请上传封面图')
  }
   
  this.addForm.courseId = this.courseId
  this.addForm.game_type = this.gameType

  const {data:res} = await contentAdd(this.addForm)
  if(res.status!=0){
    return this.$message.error(res.msg)
  }
  
  this.$message.success(res.msg)
  window.sessionStorage.setItem('courseTab',null)  
}
},
}
</script>

<style scoped>
.clear {
  clear: both;
}
.progress{
 position: absolute;
 margin-left:26px;
 margin-top:-162px;
}
.line {
  width: 200px;
}
.el-icon-plus:before {
  line-height: 178px;
}
</style>