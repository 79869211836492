<template>
    <div>
      <el-card>
        <el-row>
          <el-col>
            <el-button type="primary" v-if="checkPermission('admin.auth.add')" @click="addDialogVisible = true" >添加权限</el-button>
          </el-col>
        </el-row>
        <el-row style="margin-top:20px">
          <el-table :data="authList" border stripe>
            <el-table-column type="index"></el-table-column>
            <el-table-column label="权限名称" prop="htmlname"></el-table-column>
            <el-table-column label="排序" prop="sort" width="50px"></el-table-column>
            <el-table-column label="权限路径" prop="route_name"></el-table-column>
            <el-table-column label="菜单" prop="is_menu">
              <template slot-scope="scope">
                <el-tag v-if="scope.row.is_menu === 0" type="danger">否</el-tag>
                <el-tag v-else >是</el-tag>
              </template>
            </el-table-column>
            <el-table-column label="等级" prop="level">
                <template slot-scope="scope">
                    <el-tag v-if="scope.row.level == 1" type="danger">一级</el-tag>
                    <el-tag v-else-if="scope.row.level == 2 ">二级</el-tag>
                    <el-tag v-else-if="scope.row.level == 3">三级</el-tag>
                    <el-tag v-else>四级</el-tag>
                </template>
            </el-table-column>
            <el-table-column label="操作" width="180px">
                <template slot-scope="scope" >
                <el-button type="primary" icon="el-icon-edit" v-if="checkPermission('admin.auth.edit')" size="mini" @click="showEditDialog(scope.row)" ></el-button>
                <el-popconfirm title="确定要删除这个权限嘛?" @onConfirm="delAuth(scope.row.id)" @confirm="delAuth(scope.row.id)">
                <el-button slot="reference" type="danger" v-if="checkPermission('admin.auth.del')" icon="el-icon-delete" size="mini"></el-button>
                </el-popconfirm>
                </template>
            </el-table-column>
          </el-table>
        <!--分页start-->
        <!-- <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="queryInfo.pagenum"
          :page-sizes="[10, 20, 30, 50]"
          :page-size="queryInfo.pagesize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total">
        </el-pagination> -->
        <!--分页end-->
        </el-row>       
      </el-card>

    <!--添加权限start-->  
    <el-dialog title="添加权限"  :visible.sync="addDialogVisible" width="28%" @close="addDialogClosed" >
      <el-form :model="addForm" :rules="addFormRules" ref="addFormRef" label-width="70px">
        <el-form-item label="权限名" prop="name">
          <el-input v-model="addForm.name"></el-input>
        </el-form-item>
        <el-form-item label="路径" prop="route_name">
          <el-input v-model="addForm.route_name" placeholder="admin.index.index"></el-input>
        </el-form-item>
        <el-form-item label="上级权限">
          <el-select v-model="selectedAuthId" placeholder="请选择">
            <el-option value="0" label="顶级权限">顶级权限</el-option>
            <el-option v-for="item in menuList" :key="item.id"  :label="item.htmlname" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="排序">
          <el-input v-model="addForm.sort"></el-input>
        </el-form-item>
        <el-form-item label="图标" prop="icon">
          <el-input v-model="addForm.icon"></el-input>
        </el-form-item>
        <el-form-item label="菜单" >
          <el-radio-group v-model="addForm.is_menu">
            <el-radio label="1">是</el-radio>
            <el-radio label="0">否</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addDialogVisible = false">取消</el-button>
        <el-button type="primary" @click="addAuth">确定</el-button>
      </span>
    </el-dialog>
    <!--添加权限end-->

     <!--修改权限start-->  
    <el-dialog title="修改权限"  :visible.sync="editDialogVisible" width="28%" @close="editDialogClosed" >
      <el-form :model="editForm" :rules="editFormRules" ref="editFormRef" label-width="70px">
        <el-form-item label="权限名" prop="name">
          <el-input v-model="editForm.name"></el-input>
        </el-form-item>
        <el-form-item label="路径" prop="route_name">
          <el-input v-model="editForm.route_name" placeholder="admin.index.index"></el-input>
        </el-form-item>
        <el-form-item label="上级权限">
          <el-select v-model="editSelectedAuthId" placeholder="请选择">
            <el-option :value="0" label="顶级权限">顶级权限</el-option>
            <el-option v-for="item in menuList" :key="item.id"  :label="item.htmlname" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="排序">
          <el-input v-model="editForm.sort"></el-input>
        </el-form-item>
        <el-form-item label="图标" prop="icon">
          <el-input v-model="editForm.icon"></el-input>
        </el-form-item>
        <el-form-item label="菜单" >
          <el-radio-group v-model="editForm.is_menu">
            <el-radio :label="1">是</el-radio>
            <el-radio :label="0">否</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="editDialogVisible = false">取消</el-button>
        <el-button type="primary" @click="editAuth">确定</el-button>
      </span>
    </el-dialog>
    <!--修改权限end-->
    </div>
</template>


<script>
import { list,authAdd, authEdit, authDel } from '@/api/auth'

export default {
data() {
  return {
    authList: [],
    menuList: [],
    queryInfo: {
        query: '',
        pagenum: 1,
        pagesize: 10
    },
    total: 0,
    // 添加
    addDialogVisible: false, 
    addForm: {
      name: '',
      is_menu: '1',
      route_name: '',
      icon: '',
      sort: 0,
    }, 
    selectedAuthId: '' , 
    addFormRules: {
      name: [{ required: true, message: '请输入权限名', trigger: 'blur' }],
      route_name: [{ required: true, message: '请输入路径', trigger: 'blur' }]
    },
    // 修改
    editDialogVisible: false,
    editSelectedAuthId: '',
    editForm: {
      name: '',
      is_menu: 0,
      route_name: '',
      icon: '',
      sort: 0,
      id: '',
    },
    editFormRules: {
      name: [{ required: true, message: '请输入权限名', trigger: 'blur' }],
      route_name: [{ required: true, message: '请输入路径', trigger: 'blur' }]
    },
 }
},
created() {
    this.getAuthList()
},
methods: {
    async getAuthList() {
        const {data:res} = await list()
        if(res.status!=0){
            return this.$message.error(res.msg)
        }
        this.authList = res.data.auth
        this.menuList = res.data.menus
    },
    // 分页
    handleSizeChange(newSize) {
        this.queryInfo.pagesize = newSize
        this.getAuthList()
        
    },
    handleCurrentChange(newPage) {
        this.queryInfo.pagenum = newPage
        this.getAuthList()
    },
    // 添加权限
    addDialogClosed() {
        this.$refs.addFormRef.resetFields()
    },
    async addAuth() {
        this.addForm.pid = this.selectedAuthId
        const { data:res } = await authAdd(this.addForm)
        if (res.status == -1){
            return this.$message.error(res.msg)
        }
        this.addDialogVisible = false
        this.getAuthList()
    },
    // 权限修改
    showEditDialog(row) {
      console.log(row)
      this.editForm.name = row.name
      this.editForm.route_name = row.route_name
      this.editSelectedAuthId = row.pid
      this.editForm.icon = row.icon
      this.editForm.sort = row.sort
      this.editForm.is_menu = row.is_menu
      this.editForm.id = row.id
      this.editDialogVisible = true
    },
    editDialogClosed() {
      this.$refs.editFormRef.resetFields()
    },
    async editAuth() {
      this.editForm.pid = this.editSelectedAuthId
      const {data:res} = await authEdit(this.editForm)
      if(res.status!=0){
        return this.$message.error(res.msg)
      }
      this.editDialogVisible = false
      this.getAuthList()
    },
    // 删除
    confirm() {
      this.visible = false;
      this.$emit('onConfirm');
    },
    async delAuth(id) {
      const {data:res} = await authDel({"id":id})
      if(res.status!=0){
        return this.$message.error(res.msg)
      }
      this.$message.success(res.msg)
      this.getAuthList()
    }
}
}
</script>


<style lang="sass">

</style>