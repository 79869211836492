import request from '@/utils/request'

//----------数据统计-------------

export function list(data) {
  return request({
    url: '/admin/interactStatistics/getList',
    method: 'post',
    data
  })
}


export function getStatisticsDay(data) {
  return request({
    url: '/admin/InteractStatistics/getStatisticsDay',
    method: 'post',
    data
  })
}

export function getStatisticsLogs(data) {
  return request({
    url: '/admin/InteractStatistics/getStatisticsLogs',
    method: 'post',
    data
  })
}