import request from '@/utils/request'

//--------------------课程订单----------------------

// 订单列表
export function list(data) {
  return request({
    url: '/admin/interactOrder/list',
    method: 'post',
    data
  })
}

// 退款
export function refundApi(data) {
  return request({
    url: '/admin/interactOrder/applyOrderRefund',
    method: 'post',
    data
  })
}

// 退款日志
export function refundRecord(data) {
  return request({
    url: '/admin/interactOrder/getOrderRefundLog',
    method: 'post',
    data
  })
}


// 订单隐藏
export function orderHidden(data) {
  return request({
    url: '/admin/interactOrder/orderHidden',
    method: 'post',
    data
  })
}