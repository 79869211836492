import request from '@/utils/request'

// 权限列表
export function list() {
  return request({
    url: '/admin/auth/list',
    method: 'post'
  })
}

export function subTreeList(data) {
  return request({
    url: '/admin/auth/subTreeList',
    method: 'post',
    data
  })
}


export function authAdd(data) {
  return request({
    url: '/admin/auth/authAdd',
    method: 'post',
    data
  })
}

export function authEdit(data){
  return request({
    url: '/admin/auth/authEdit',
    method: 'post',
    data
  })
}

export function authDel(data){
  return request({
    url: '/admin/auth/authDel',
    method: 'post',
    data
  })
}