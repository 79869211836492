<template>
  <div class="cc-calendar" 
   v-loading="isShow" 
    element-loading-text="拼命加载中"
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0.8)"
   >
    <calendarHeader 
      :headOptions="headOptions" 
      @handlePrevMonth = 'handlePrevMonth'
      @handleNextMonth = 'handleNextMonth'
      @handleToday = 'handleToday'
      @changeYear="changeYear"
      @changeMonth="changeMonth"
    />
    <ul class="calendar-week">
      <li v-for="(item, index) in calendarTitleArr" :key="index" class="week-item">{{item}}</li>
    </ul>
    <ul class="calendar-view">
      <li v-for="(item, index) in calendarList" 
        :key="index" 
        class="date-view"
        :class="[
          {'month-class': !isCurrentMonth(item.date)},
          {todayBg: isCurrentDay(item.date)},
          {handleDay: item.clickDay}
        ]"
        @click="handleClickDay(item,index)"
      >
        <span class="date-day" 
          :style="dayStyle" 
          :class="[{'opacity-class': !isCurrentMonth(item.date)}]"
        >
          {{item.day}}
        </span>
        <span class="calendar-num">
          {{item.calendarNum}}
        </span>
      </li>
    </ul>

    <!--调整排课 start-->
    <el-dialog title="调整排课" :close-on-click-modal="false" :visible.sync="addDialogVisible" width="30%">
      <el-form ref="addFormRef"  label-width="100px">
        <el-form-item label="当日安排:">
          <div>
            <div>
              <el-radio :label="1" v-model="clickDayStatus">上课 </el-radio>
            </div>
            <div>
              <el-radio :label="2" v-model="clickDayStatus">休息</el-radio>
            </div>
          </div>
        </el-form-item>    
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addDialogVisible = false">取消</el-button>
        <el-button type="primary" @click="changeSta">确认</el-button>
      </span>
    </el-dialog>
    <!--调整排课 end-->
  </div>
</template>

<script>
  import './reset.min.css'
  import calendarHeader from './Header.vue';
  import * as utils from './util.js';
  import {list, changeStatus} from '@/api/calendar'
  

  export default {
    // name: 'cc-calendar',
    // componentName: 'cc-calendar',
    // props: {
    //   options: Object
    // },
    components: {
      calendarHeader
    },
    data() {
      let {year, month, day} = utils.getNewDate(new Date());
      return {
        calendarArr: {
          type: 'combination',
          headStyle: {
            todayBtn: 'right',
            combination: 'center',
            checkBtn: 'right',
          },
          viewStyle: {
            day: 'right'
          },
          calendarData: []
        },
        headOptions: {
          type: 'combination',
          style: {
            todayBtn: 'right',
            combination: 'center',
            checkBtn: 'right',
          },
          date: '',
        },
        calendarTitleArr: [
          '一',
          '二',
          '三',
          '四',
          '五',
          '六',
          '日'
        ],
        time: {year, month, day},
        calendarList: [],
        date:'',
        clickDayStatus:'1',
        addDialogVisible:false,
        isShow:false,
        obj:{
          date:"",
          status:""
        }
      }
    },
    created () {
      this.getList()
      // this.calendarList = this.visibleCalendar
      // console.log(this.calendarList)
      // this.calendarType = this.calendarArr.calendarType;
    },
    computed : {
      dayStyle : function () {
        return {
          textAlign: this.calendarArr.viewStyle.day,
        }
      },
      // visibleCalendar () {
      //   let calendatArr = [];
      //   let {year, month, day} = utils.getNewDate(utils.getDate(this.time.year, this.time.month, 1));
        
      //   let currentFirstDay = utils.getDate(year, month, 1);

      //   // 获取当前月第一天星期几
      //   let weekDay = currentFirstDay.getDay();
      //   if (weekDay == 0) {
      //     weekDay += 7;
      //   }
        
      //   // 页面初始时间
      //   let startTime = currentFirstDay - (weekDay - 1) * 24 * 60 * 60 * 1000;

      //   let monthDayNum;
      //   if (weekDay == 5 || weekDay == 6){
      //     monthDayNum = 42
      //   }else {
      //     monthDayNum = 35
      //   }

      //   console.log(this.calendarArr.calendarData)

      //   // 接口获取数据
      //   for (let i = 0; i < monthDayNum; i++) {
      //     // console.log(new Date(startTime + i * 24 * 60 * 60 * 1000).getDate())
      //     calendatArr.push({
      //       date: new Date(startTime + i * 24 * 60 * 60 * 1000),
      //       year: year,
      //       month: month + 1,
      //       day: new Date(startTime + i * 24 * 60 * 60 * 1000).getDate(),
      //       clickDay: false,
      //     })
      //   };
        
      //   // this.headOptions.date = `${utils.englishMonth(month)} ${year}`;
      //   this.headOptions.date = `${month+1}/${year}`;
      //   return calendatArr
      // }
    },
    methods: {
      // 是否是当前月
      isCurrentMonth (date) {
        let {year: currentYear, month: currentMonth} = utils.getNewDate(utils.getDate(this.time.year, this.time.month, 1));
        let {year, month} = utils.getNewDate(date);
        return currentYear == year && currentMonth == month
      },
      // 是否是今天 
      isCurrentDay (date) {
        let {year: currentYear, month: currentMonth, day: currentDay} = utils.getNewDate(new Date());
        let {year, month, day} = utils.getNewDate(date);
        return currentYear == year && currentMonth == month && currentDay == day;
      },
      // 上一个月  76271afda
      handlePrevMonth () {
        let prevMonth = utils.getDate(this.time.year,this.time.month,1);
        prevMonth.setMonth(prevMonth.getMonth() - 1);
        this.time = utils.getNewDate(prevMonth);
        this.headOptions.date = `${utils.englishMonth(this.time.month)} ${this.time.year}`;
        this.$emit('handlePrevMonth');
      },
      // 下一个月
      handleNextMonth () {
        let nextMonth = utils.getDate(this.time.year,this.time.month,1); 
        nextMonth.setMonth(nextMonth.getMonth() + 1);
        this.time = utils.getNewDate(nextMonth);
        this.headOptions.date = `${utils.englishMonth(this.time.month)} ${this.time.year}`;
        this.$emit('handleNextMonth');
      },
      // 点击回到今天
      handleToday () {
        this.time = utils.getNewDate(new Date());
        // this.returnDate();
        this.$emit('handleToday');
      },
      // 点击某一天
      handleClickDay (item,index) {
      
        if(item.date.getTime()<(new Date()).getTime()){
          return this.$message.error("无法修改今日及之前的内容")
        }
        //183000
        
        this.addDialogVisible = true
        this.clickDayStatus = 1
        let status = 1
        if(this.calendarList[index].clickDay === false){
          this.clickDayStatus = 2
          status = 2
        }

        this.obj.date = this.calendarList[index].year+"-"+this.forMatNum(this.calendarList[index].month)+"-"+this.forMatNum(this.calendarList[index].day)
        this.obj.status = status

        console.log(this.calendarList[index],status)
        // 改变颜色
        // this.calendarList[index]. clickDay = !this.calendarList[index]. clickDay

        this.$forceUpdate();
        this.$emit('handleClickDay', item);
        // this.$set(item, 'clickDay', true);
      },
      async changeSta(){ 
        if(this.clickDayStatus == this.obj.status) {
          this.$message.error("请切换状态之后再点击确认")
          return
        }
        this.addDialogVisible = false
        this.isShow = true

        const {data:res} = await changeStatus(this.obj)
        if(res.status!=0){
          this.isShow = false
          return this.$message.error(res.msg)
        }
        
        this.getList()
        this.isShow = false
              
      },
      // 改变年
      changeYear(year_month){
        let date = utils.getDate(year_month[0],year_month[1]-1,1); 
        this.time = utils.getNewDate(date);
        this.date = year_month[0]+'-'+year_month[1]
        this.getList()
      },
      // 改变月
      changeMonth(year_month){
        let date = utils.getDate(year_month[0],year_month[1]-1,1); 
        this.time = utils.getNewDate(date);
        this.date = year_month[0]+'-'+year_month[1]
        this.getList()
      },
      visibleCalendar () {
        let calendatArr = [];
        let {year, month, day} = utils.getNewDate(utils.getDate(this.time.year, this.time.month, 1));
        this.date = year+'-'+this.forMatNum(`${month+1}`)
        
        let currentFirstDay = utils.getDate(year, month, 1);

        // 获取当前月第一天星期几
        let weekDay = currentFirstDay.getDay();
        if (weekDay == 0) {
          weekDay += 7;
        }
        
        // 页面初始时间
        let startTime = currentFirstDay - (weekDay - 1) * 24 * 60 * 60 * 1000;

        let monthDayNum;
        if (weekDay == 5 || weekDay == 6){
          monthDayNum = 42
        }else {
          monthDayNum = 35
        }

        // console.log(this.calendarArr.calendarData)

        // 接口获取数据
        for (let i = 0; i < monthDayNum; i++) {
          // console.log(new Date(startTime + i * 24 * 60 * 60 * 1000).getDate())
          const date = new Date(startTime + i * 24 * 60 * 60 * 1000)
          calendatArr.push({
            date: date,
            year: year,
            month: date.getMonth() + 1,
            day: new Date(startTime + i * 24 * 60 * 60 * 1000).getDate(),
            clickDay: false,
          })
        };
        
        // this.headOptions.date = `${utils.englishMonth(month)} ${year}`;
        this.headOptions.date = `${month+1}/${year}`;
        return calendatArr
      },
      forMatNum(val){
        if(val<10){
          return '0'+val
        }
        return val
      },
      async getList(){
        const visibleData = this.visibleCalendar()
        const {data:res} = await list({month:this.date})
        
        if(res.data){
          visibleData.forEach(element =>{
            const str = element.year+"-"+this.forMatNum(element.month)+"-"+this.forMatNum(element.day)
             for(let i in res.data){
              if(res.data[i].date == str){
                if(res.data[i].status == 2){
                  element.clickDay = false
                }else{
                  element.clickDay = true
                }
              }
             }
          })
        }

        // console.log(visibleData)
        this.calendarList = visibleData
      }
    },  
  }
</script>

<style lang="less">
  .cc-calendar {
    padding: 23px 30px 30px;
    width: 100%;
    height: 80%;
    background: #F9FAFC;
    box-sizing: border-box;
    .calendar-week {
      display: flex;
      width: 100%;
      height: 46px;
      line-height: 46px;
      border: 1px solid #181a1b;
      border-right: none;
      .week-item {
        width: 14.285%;
        text-align: center;
        font-size: 14px;
        color: #424953;
        border-right: 1px solid #18191a;
        font-weight: 600;
      }
    }
    .calendar-view {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      border-left: 1px solid #1d1e20;
      .date-view {
        width: 14.285%;
        height: 100px;
        border-right: 1px solid #191a1b;
        border-bottom: 1px solid #1b1c1d;
        box-sizing: border-box;
        cursor: pointer;
        .date-day {
          padding: 8px 8px 0;
          display: block;
          width: 100%;
          font-size: 14px;
          color: #7F8794;
        }
        .calendar-num {
          margin-top: 6px;
          display: block;
          width: 100%;
          text-align: center;
          font-size: 30px;
          color: #424953;
        }
      }
      .opacity-class {
        opacity: .5;
      }
      .month-class {
        background-image: linear-gradient(45deg,rgba(000, 000, 000, .03) 25%,transparent 25%,transparent 50%,rgba(000, 000, 000, .03) 50%,rgba(000, 000, 000, .03) 75%,transparent 75%,transparent);
        background-size: 20px 20px;
      }
      .todayBg {
        background: #FFFDEF;
      }
      .handleDay {
        background: #caf982 !important;
        .date-day {
          color: #01040c !important;
        }
        .calendar-num {
          color: #fff !important;
        }
      }
    }
  }
</style>