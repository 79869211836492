import request from "@/utils/request";

//--------------课表------------------
//--------------课表------------------

export function list(data) {
  return request({
    url: "/admin/courseTime/lists",
    method: "post",
    params: data,
  });
}

export function addCourseTime(data) {
  return request({
    url: "/admin/courseTime/add",
    method: "post",
    params: data,
  });
}

export function updateCourseTime(data) {
  return request({
    url: "/admin/courseTime/update",
    method: "post",
    params: data,
  });
}

export function advanceCourseTime(data) {
  return request({
    url: "/admin/courseTime/advance",
    method: "post",
    params: data,
  });
}

// 课表删除
export function deleteTimeTable(data) {
  return request({
    url: "/admin/courseTime/delete",
    method: "post",
    params: data,
  });
}


// 报名信息
export function getSignUpInfoList(data) {
  return request({
    url: "/admin/courseTime/getSignUpInfoList",
    method: "post",
    params: data,
  })
}

// 用户学习记录
export function timeLearnRecordsOfUser(data) {
  return request({
    url: "/admin/courseTime/timeLearnRecordsOfUser",
    method: "post",
    params: data,
  })
}


// 客服list
export function customList(data) {
  return request({
    url: "/admin/courseTime/timeLearnRecordsOfUser",
    method: "post",
    params: data,
  })
}
