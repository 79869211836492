import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '@/views/login/Login.vue'
import Home from '@/views/home/Home.vue'
import Welcome from '@/views/home/Welcome.vue'
import Test from '@/views/test/TestUpload.vue'
// import Layout from '@/layout/index.vue'
import User from '@/views/user/User.vue'
import Auth from '@/views/auth/Auth.vue'
import Role from '@/views/role/Role.vue'
import Course from '@/views/course/Course.vue'
import CourseContent from '@/views/course/CourseContent.vue'
import CourseContentV2 from '@/views/course/CourseContentV2.vue'
import CourseCate from '@/views/course/CourseCate.vue'
import BagCate from '@/views/coursePackage/BagCate.vue'
import MixCP from '@/views/coursePackage/MixCP.vue'
import RelatedC from '@/views/coursePackage/RelatedCourse.vue'
import ThemeManage from '@/views/coursePackage/ThemeManage.vue'
import BuyUrl from '@/views/coursePackage/CreateUrl.vue'
import Schooltt from '@/views/coursePackage/SchoolTimeTable.vue'
import UserSignUp from '@/views/coursePackage/UserSignUp.vue'
import ClassRecord from '@/views/coursePackage/ClassRecords.vue'
import COrder from '@/views/coursePackage/CourseOrder.vue'
import CUser from '@/views/courseUser/User.vue'
import BuyRecords from '@/views/courseUser/BuyRecords.vue'
import LearnRecord from '@/views/courseUser/LearnRecords.vue'
import Banner from '@/views/market/Banner.vue'
import Alert from '@/views/market/Alert.vue'
import Calendar from '@/views/system/calendar/Calendar.vue'
import Message from '@/views/system/message/Message.vue'
import MessageRecords from '@/views/system/message/MessageRecords.vue'
import CustomSer from '@/views/system/CustomSer.vue'
import Static from '@/views/static/CourseBagStatic.vue'
import StaticDaily from '@/views/static/Daily.vue' 
import Upload from '@/views/upload/Upload.vue'  // test js 文件上传
import ExplainDocument from '@/views/market/ExplainDocument.vue'


Vue.use(VueRouter)

const routes = [
  { path: '/', redirect: '/login' },
  { path: '/login', component: Login },
  {
    path: '/home',
    component: Home,
    redirect: '/welcome',
    // redirect: '/admin.user',
    children: [
      //-------系统
      { path: '/welcome', component: Welcome },
      { path: '/admin.user',redirect: '/admin.user.index'}, // 用户管理
      { path: '/admin.role',redirect: '/admin.role.index'}, // 角色管理
      { path: '/admin.auth',redirect: '/admin.auth.index'}, // 角色管理
      { path: '/admin.user.index', component: User },
      { path: '/admin.auth.index', component: Auth },
      { path: '/admin.role.index', component: Role },

      { path: '/admin.calendar', component: Calendar }, //系统管理-->日历
      
      { path: '/admin.message', component: Message }, //系统管理-->消息通知
      { path: '/admin.messageRecords', component: MessageRecords }, //系统管理-->消息通知-->发送记录

      { path: '/admin.customSer', component: CustomSer }, //系统管理-->客服管理

      //--------------------课程--------------
      { path: '/admin.course.index', component: Course }, // 课程列表
      { path: '/admin.course.content', component: CourseContent }, // 课程内容
      { path: '/admin.course.contentV2', component: CourseContentV2 }, // 课程内容
      { path: '/admin.course.cate', component: CourseCate }, // 课程分类
      { path: '/admin.bag.cate', component: BagCate }, // 课包分类

      // 混合课包
      { 
        path: '/admin.course.bag', 
        component: MixCP 
        // component: resolve => require(['@/views/coursePackage/MixCP.vue'],resolve) 
      }, 
      { path: '/admin.course.relatedcourse', component: RelatedC }, // 关联课程
      { path: '/admin.course.themeManage', component: ThemeManage }, // 混合课包-->管理主题
      { path: '/admin.course.buyUrl', component: BuyUrl }, // 混合课包-->生成购买链接

      { path: '/admin.course.schooltt', component: Schooltt }, // 课表管理
      { path: '/admin.course.userSignUp', component: UserSignUp }, // 课表-->报名信息
      { path: '/admin.course.classRecord', component: ClassRecord }, // 课表-->报名信息-->上课记录
      { path: '/admin.course.order', component: COrder }, // 课程订单
      //-----用户管理
      { path: '/admin.courseuser.index', component: CUser }, // 课程用户管理-->用户列表
      { path: '/admin.courseuser.buyRecords', component: BuyRecords }, // 课程用户管理-->用户列表--> 购课记录
      { path: '/admin.courseuser.learnRecord', component: LearnRecord }, // 课程用户管理-->用户列表--> 购课记录-->上课记录
      //-----营销管理
      { path: '/admin.banner', component: Banner }, // banner
      { path: '/admin.alert', component: Alert }, // alert
      { path: '/admin.explain-document', component: ExplainDocument }, // 说明文档
      //-----数据包分析
      { path: '/admin.course-data', component: Static }, 
      { path: '/admin.course-data-daily', component: StaticDaily }, 
      //-------文件上传
      { path: '/admin.upload', component: Upload }, 
    ]
  },
  {path: '/test', component: Test}
]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router
