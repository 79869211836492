<!--关联课程-->
<template>
  <div>
    <el-card>
      <el-row>
        <el-col :span="6">课程名称: <el-input type="text"  prefix-icon="el-icon-search" v-model="search.keyword"></el-input></el-col>
        <el-button  type="primary" size="medium" icon="el-icon-search" @click="btnClick" style="margin-top:15px;margin-left:10px">搜索</el-button>
      </el-row>
      <el-row style="margin-top:10px;">
        <el-button type="danger" @click="back" >返回主题</el-button>
        <el-button type="info" @click="add" v-if="show == 0">关联课程</el-button>
        <el-button type="primary" @click="sort" v-if="show == 0">{{sortText}}</el-button>
        <el-button type="success" @click="updateSort" v-if="show == 0">刷新排序</el-button>
      </el-row>
      <el-row style="margin-top:10px;">
          <el-table :data="courseRelationList" 
          border 
          stripe  
          >
          <el-table-column label="课程编号" prop="course_no"></el-table-column>
          <el-table-column label="课程名称" prop="course_title"></el-table-column>

          <el-table-column label="课程排序" >
            <template slot-scope="scope">
              <el-input type="number" v-model=" cpList[scope.$index].course_sort" :disabled="!sortStatus" ></el-input>
            </template>
          </el-table-column>

          <el-table-column label="操作" width="240px">
              <template slot-scope="scope" >
                <el-popconfirm title="确定要删除嘛?" @onConfirm="delButton(scope.row.id)" @confirm="delButton(scope.row.id)">
                  <el-button  type="text" slot="reference" size="mini" v-if="show == 0">删除</el-button>
                </el-popconfirm>
              </template>
          </el-table-column>
        </el-table>

       <!--分页start-->
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="search.pagenum"
          :page-sizes="[10, 20, 30, 50]"
          :page-size="search.pagesize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total">
        </el-pagination>
        <!--分页end--> 

      </el-row>
    </el-card> 

    <!-------------------关联课程------------------------->
    <el-dialog title="关联课程" :close-on-click-modal="false" :visible.sync="addDialogVisible" width="70%" @close="addDialogClosed" >
      <div style="float:left;width:100%">
        <!--未关联部分-->
        <div style="width:70%;float:left;border-right:1px solid #ccc">
          <div>
            <el-form label-width="100px" @submit.native.prevent>
              <el-form-item label="课程搜索">
                <el-input type="text" @blur="searchBlur" v-model="search.keyword" style="width:40%" placeholder="输入课程编号或名称" prefix-icon="el-icon-search"></el-input>
              </el-form-item>
            </el-form>
          </div>
          <div >
            <div style="width:95%;height:500px;overflow-y:scroll">
              <el-table
              ref="table" 
              :row-style="{height:'45px'}" 
              :cell-style="{padding:'0px'}"
              :data="courseList" border  
              @selection-change="handleSelectionChange"
              >
                <el-table-column type="selection" width="55"> </el-table-column>
                <el-table-column label="课程编号" prop="course_no"></el-table-column>
                <el-table-column label="课程名称" prop="title"></el-table-column>
              </el-table>
            </div>
          </div>
        </div>
          <!-- 关联课程-->
        <div style="float:left;width:30%;height:500px;overflow-y:scroll">
          <div class="r-div-son" v-for="(item,index) in courseRight" :key="index"> 
            <span >{{item.course_title}}</span>
            <img src="@/assets/common/delete.png" alt="" class="delete"  @click="del(index)"/>
          </div>          
        </div>
      </div>
      <div class="clear" style="height: 10px;"></div>
      <div style="width:100%;margin-top:20px;height: 50px;">
        <div style="width:200px;float: right;">
          <span slot="footer" class="dialog-footer">
            <el-button @click="addDialogVisible = false">取消</el-button>
            <el-button type="primary" @click="addRelationCourse">确定</el-button>
          </span>
        </div>
      </div>  
      <div class="clear" style="height: 10px;"></div>
    </el-dialog>
    <!--关联课程-->
  </div>
</template>


<script>
import {courseRelatedList,courseNoRelatedList, addCourse, sort, delRelaCourse} from '@/api/coursePackage'
export default {
 data (){
  return {
    cpId: this.$route.query.cpId, // 课包id
    subjectId: this.$route.query.subjectId, // 主题id
    show: this.$route.query.show, 
    search: {
      pagenum: 1,
      pagesize: 10,
      course_bag_id: this.$route.query.cpId,
      course_bag_subject_id:'0',
      keyword:''
    },
    cpList:[],
    addDialogVisible: false,
    progress: false,
    courseList:[],  //未关联课程
    courseRight:[], 
    courseRelationList:[], // 已关联课程
    multipleSelection: [],
    //---------排序--------------
    sortStatus:0,
    sortText:'编辑排序',
    total:0,
  }
 },
 created() {
   this.getRelationCourseList()
 },
 methods: {
  searchBlur(){
    this.getNoRelationCourseList()
  },
  // 添加 dialog
  add(){
    this.getRelationCourseList()
    this.getNoRelationCourseList()
    this.addDialogVisible = true
  },
  addDialogClosed(){
    this.courseRight = []
    this.courseList = []
    this.search.keyword = ''
  },
  // 关联课程列表
  async getRelationCourseList(query) {
      if(this.subjectId){
        this.search.course_bag_subject_id = this.subjectId
      } 
      const {data:res} = await courseRelatedList(this.search)
      if(res.status!=0){
        return this.$message.error(res.msg)
      }
      this.total = res.data.total
      
      this.courseRelationList = []
      this.cpList = []
      res.data.data.forEach((value)=>{
        this.courseRelationList.push(value) ;
        this.cpList.push(value)
      })     
  },
  // 未关联课程列表
  async getNoRelationCourseList(query) {
      if(this.subjectId){
        this.search.course_bag_subject_id = this.subjectId
      } 
      const {data:res} = await courseNoRelatedList(this.search)
      if(res.status!=0){
        return this.$message.error(res.msg)
      }
      this.courseList = res.data
  },
  // 添加关联课程
  async addRelationCourse(){
  
      const idArr = []
      this.courseRight.forEach(value=>{
        idArr.unshift(value.course_id)
      })
      const be_course_ids = idArr.join(',')
      const {data:res} = await addCourse({'course_bag_id': this.$route.query.cpId,'course_bag_subject_id':this.search.course_bag_subject_id,'be_course_ids':be_course_ids})
      if(res.status!=0){
        return this.$message.error(res.msg)
      }
      this.addDialogVisible = false
      this.getRelationCourseList()
  },
  //----------------------------------排序----------------------------
  async sort(){
    if(this.sortStatus == 0){
       this.sortStatus = 1
      this.sortText = "保存排序"
    }else{
      this.sortStatus = 0
      this.sortText = "编辑排序"
      const {data:res} = await sort({sort_data:JSON.stringify(this.cpList),bag_id:this.cpId,subjectId:this.subjectId})
      if(res.status!=0){
        return this.$message.error(res.msg)
      }
    }
  },
  updateSort(){
    this.getRelationCourseList()
  },
  //-----------------关联课程 toggle------------------------
  handleSelectionChange(val){
    this.multipleSelection = val
    this.multipleSelection.forEach((val)=>{
      this.courseList.forEach((cVal,index)=>{
        if(cVal.id == val.id){
          this.courseList.splice(index,1)
          const item = {
            'course_id': val.id,
            'course_no': val.course_no,
            'course_title':val.title
          }
          // 过滤： 课程重复添加
          let ret = 0
          this.courseRight.forEach((eml)=>{
            if(eml.course_id == item.course_id){
              ret = -1
            }
          })
          if(ret != -1){
            this.courseRight.unshift(item)
          }      
        }
      })
    })
    this.$refs.table.clearSelection()
  },
  //------------
  del(index){
    const item = {
      'course_no':this.courseRight[index].course_no,
      'id':this.courseRight[index].course_id,
      'title':this.courseRight[index].course_title
    }
    this.courseList.unshift(item)
    this.courseRight.splice(index,1)  
  },
  // --------------搜索------
  btnClick(){
    this.search.page = 1
    this.search.size = 10
    this.getRelationCourseList()
  },
  handleSizeChange(newSize) {
      this.search.pagesize = newSize
      this.getRelationCourseList()
  },
  handleCurrentChange(newPage) {
    this.search.pagenum = newPage
    this.getRelationCourseList()
  },
  //--------------------------关联课程删除-----------------------------
  confirm() {
    this.visible = false;
    this.$emit('onConfirm');
  },
  async delButton(id){
    const {data:res} = await delRelaCourse({be_course_id:id})
    if(res.status!=0){
      return this.$message.error(res.msg)
    }
    this.$message.success('删除成功!')
    this.getRelationCourseList()
  },
  //---------------- 跳转到 管理主题----------------------
  back() {
    this.$router.push({ path: '/admin.course.themeManage', query: { cpId: this.cpId } })
  },
 } 
}
</script>

<style scoped>

.r-div-son{
  border:1px solid #ccc;
  height:33px; 
  border-radius:20px;
  line-height: 33px;
  margin: 5px 0 5px 10px;
  width:95%;
}
.r-div-son span {
  margin-left:10px;
}

.clear{
  clear:both;
}

.delete {
  width:28px;
  height: 28px;
  color:red;
  position: absolute;
  right: 2px;
  z-index: 100;
  margin-right: 45px;
}
</style>