import request from '@/utils/request'
import axios from 'axios'

//------------------------------课程包----------------------------------------------
//----------------------------------------------------------------------------------

export function list(data) {
  return request({
    url: '/admin/courseBag/lists',
    method: 'get',
    params:data
  })
}

// 课包添加
export function addCp(data) {
  return request({
    url: '/admin/courseBag/add',
    method: 'post',
    data
  })
}

//更新
export function update(data) {
  return request({
    url: '/admin/courseBag/update',
    method: 'post',
    data
  })
}


// 详情
export function show(data) {
  return request({
    url: '/admin/courseBag/show',
    method: 'get',
    params:data
  })
}

// 启用/禁用
export function changeStatus(data) {
  return request({
    url: '/admin/courseBag/change_status',
    method: 'post',
    data
  })
}

// 是否已有推荐
export function checkRecommend(data) {
  return request({
    url: '/admin/courseBag/check_recommend_bag',
    method: 'post',
    data
  })
}

// 推荐课程
export function recommend(data) {
  return request({
    url: '/admin/courseBag/recommend_bag',
    method: 'post',
    data
  })
}


// 前端 显示/隐藏
export function frontShowToggle(data) {
  return request({
    url: "/admin/courseBag/change_web_status",
    method: "post",
    params: data,
  });
}

// 课包删除
export function delCourseBag(data) {
  return request({
    url: "/admin/courseBag/delete",
    method: "post",
    data
  });
}

//获取全部启动的课程包
export function getCourseBagAll(data) {
  return request({
    url: '/admin/courseBag/all',
    method: 'post',
    data
  })
}

//---------------------------------生成链接
export function createShortUrl(data) {
  return request({
    url: '/admin/shortLink/create',
    method: 'post',
    data
  })
}
export function linkList(data) {
  return request({
    url: '/admin/shortLink/list',
    method: 'post',
    data
  })
}


//--------------------------------------------------------------关联课程-------------------------------------------------------

// 关联课程列表
export function courseRelatedList(data) {
  return request({
    url: '/admin/beCourse/lists',
    method: 'get',
    params:data
  })
}

// 未关联课程列表
export function courseNoRelatedList(data) {
  return request({
    url: '/admin/beCourse/relation',
    method: 'post',
    data
  })
}

// 添加 关联课程
export function addCourse(data) {
  return request({
    url: '/admin/courseBag/addCourse',
    method: 'post',
    data
  })
}


// 关联课程排序
export function sort(data) {
  return request({
    url: '/admin/beCourse/sort',
    method: 'post',
    data
  })
}

// 删除关联课程
export function delRelaCourse(data) {
  return request({
    url: '/admin/beCourse/delete',
    method: 'post',
    data
  })
}

//---------------------------------------------------------------主题---------------------------------------------------------
// 添加主题
export function addSubject(data) {
  return request({
    url: '/admin/courseBagSubject/add',
    method: 'post',
    data
  })
}

// 主题列表
export function subList(data) {
  return request({
    url: '/admin/courseBagSubject/lists',
    method: 'post',
    data
  })
}

// 更新主题
export function updateSub(data) {
  return request({
    url: '/admin/courseBagSubject/update',
    method: 'post',
    data
  })
}

// 主题同步
export function syncSubject(data) {
  return request({
    url: '/admin/courseBagSubject/push',
    method: 'post',
    data
  })
}

// 删除主题
export function delSubject(data) {
  return request({
    url: '/admin/courseBagSubject/delete',
    method: 'post',
    data
  })
}