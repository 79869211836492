<template>
  <div>
    <el-form>
      <el-form-item label="展示形式">
         <el-select v-model="select_type" placeholder="">
          <el-option v-for="item in type" :label="item.name" :value="item.id" :key="item.id"></el-option>
        </el-select>
      </el-form-item>
      <template v-if="select_type == 1">
        <div style="width:178px;">
          <el-form-item label="上传视频">
            <el-upload
                class="avatar-uploader"
                action="#"
                :show-file-list="false"
                :http-request="(file)=> {uploadFile(file,1,0)}">   
                <div v-if="addForm.fileList[0].video" style="width:178px;height:35px">{{ addForm.fileList[0].video | removeDir | hidden }}</div>        
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          </el-form-item>
        </div>
      </template>        
    </el-form>
    <div class="clear"></div>
    <div style="position: relative; height: 40px">
      <el-button style="position: absolute; right: 90px" @click="back">取消</el-button>
      <el-button type="primary" style="position: absolute; right: 10px" @click="submit">确定</el-button>
    </div>
  </div>
</template>

<script>
import { contentAddV2, contentDetail} from '@/api/course'
import {getQiniuToken} from '@/api/upload'
import * as qiniu from 'qiniu-js'
export default {
data() {
  return {
    courseId: this.$route.query.id,
    addForm:{
      course_id: this.$route.query.id,
      game_type: 7,
      type:1, 
      fileList:[{video:""}],
    },
    type:[{id:1,name:'视频'}],
    select_type:1,
  }
},
filters: {
  removeDir(value) {
    let site = value.lastIndexOf("\/");
    return value.substring(site+1, value.length)
  },
  hidden(value){
    let end = value.lastIndexOf("-")
    if(end>20){
      end = 20
    }
    return value.substring(0, end)
  },
},
created(){
  this.getContentDetail()
  this.changeFatherFlag()
},
methods: {
  // ----------改变父组件的值----------- 
   changeFatherFlag(){
     this.$emit('flagChange', false)
   },
  //------------- 
  back() {
    this.$router.push("/admin.course.index"); // 取消跳转回
     window.sessionStorage.setItem('courseTabV2',null)
  },
  //-------------------------上传-----------------------------
  img_limit(val){
    let type = val.file.type
    let size = val.file.size
    let type_arr = type.split("/")
    let limit = 1   // 1M
    if(type_arr[0] == "image"){
      if(size> 1024*1024*limit ){
        return -1
      }
    }
    return 1
  },
  async uploadFile(uploadInfo,type,index){
    const {data:token} = await getQiniuToken()
    const that = this
    const observer = {
        next(res){
          // console.log(res)
        },
        error(err){
          console.log(err)
        },
        complete(res){
          if(type == 1) { 
               that.addForm.fileList[0].video = res.key
          }
          window.sessionStorage.setItem('courseTabV2',4) 
        }
    }
   
    let name = uploadInfo.file.name
    let fileName = this.getFileName(name)
    
    if(parseInt(fileName) !=NaN){
      name = fileName+"-"+this.randStr(10)+"."+this.getExt(name)
    }
   
    const observable =  qiniu.upload(uploadInfo.file, "interactcourse/"+ name, token,{},{})
    const subscription =  observable.subscribe(observer)
  },
  randStr(e){
    let str = "abacdefghijklmn123456789QWERTYUIOPASDFGHJKLZXCVBNM"
    let n = "";
    for(let i=0;i<e;i++){
      n += str.charAt(Math.floor(Math.random()*str.length))
    }
    return n
  },
  getFileName(val){
    let end = val.lastIndexOf(".")
    return val.substring(0,end)
  },
  getExt(val){
    return val.substring(val.lastIndexOf(".")+1)
  },
  getIndex(key){
    let start = key.lastIndexOf("\/")
    let end = key.lastIndexOf("-")
    let str = key.substring(start+1, end)
    if(parseInt(str) == NaN){
      return -1
    }else{
      return parseInt(str)
    }
  },
  //----------------绘本阅读提交-------------
  async submit(){
    if(this.addForm.fileList[0].video == ""){
        return this.$message.error('请上传视频')
    }
      
    const {data:res} = await contentAddV2(this.addForm)
    if(res.status!=0){
      return this.$message.error(res.msg)
    }
    this.$message.success(res.msg)
    this.getContentDetail()
    window.sessionStorage.setItem('courseTabV2',null) 
    
  },
  //--------------------------详情------------------------------------
  async getContentDetail() {
    const { data: res } = await contentDetail({
      courseId: this.$route.query.id,
      game_type: this.addForm.game_type,
    });
    if(res.data.data)
    this.addForm.fileList[0].video = res.data.data[0].video
  }
}
}
</script>

<style scoped>
.clear {
  clear: both;
}
.container{
  position: relative;
}
.imgContent {
  width:178px;
  height: 178px;
  border: 1px solid black;
}
.delete {
  width:28px;
  height: 28px;
  color:red;
  position: absolute;
  right: 2px;
  z-index: 100;
}
.img{
  width:178px;
  height:178px;
}

.disabled .el-upload--picture-card {
  display: none;
}

.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>