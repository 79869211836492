<template>
  <div>
    <el-card>
      <customerHeaderView @open_add_kf="open_add_kf" @search="search" ref="customerHeaderView" />
      <customerListView ref="customerListView" />
      <customerAddView @refresh_list="refresh_list" ref="customerAddView" />
    </el-card>
  </div>
</template>

<script>
import customerListView from "@/components/CustomSer/lists.vue"
import customerAddView from "@/components/CustomSer/add.vue";
import customerHeaderView from "@/components/CustomSer/header.vue";

export default {
  components: {
    customerListView,
    customerAddView,
    customerHeaderView
  },
  data() {
    return {

    }
  },
  created() {
    this.$nextTick(() => {
      this.$refs.customerListView.getCustomerListApi({});
    })
  },
  methods: {
    open_add_kf() {
      this.$refs.customerAddView.addDialogVisible = true
      this.$refs.customerAddView.clear();
    },
    refresh_list() {
      this.$nextTick(() => {
        this.$refs.customerListView.getCustomerListApi({});
      })
    },
    search(params) {
      this.$refs.customerListView.search.status = params.status;
      this.$refs.customerListView.getCustomerListApi({});
    }
  }
}
</script>
