import request from '@/utils/request'

export function list(){
  return request({
    url: 'admin/role/list',
    method: 'post'
  })
}

export function addRole(data){
  return request({
    url: 'admin/role/add',
    method: 'post',
    data
  })
}

export function editRole(data){
  return request({
    url: 'admin/role/edit',
    method: 'post',
    data
  })
}

export function delRole(data){
  return request({
    url: 'admin/role/delete',
    method: 'post',
    data
  })
}

export function allotAuth(data){
  return request({
    url: 'admin/role/allotAuth',
    method: 'post',
    data
  })
}