<!---------------------课程用户------------------------------->
<!----------------------------------------------------------->
<template>
  <div>
    <el-card>
      <div class="nav_1">
        <div style="float:left;">
          <div style="float:left;margin-right:5px;height:40px;line-height:40px;" >用户搜索: </div>
          <div  style="float:left;">
            <el-input placeholder="输入用户名或手机号" v-model="search.keyword" ></el-input>
          </div>
          <div style="float:left;margin-left:10px">
            <el-button  type="primary" icon="el-icon-search" @click="btnClick" >搜索</el-button>
          </div>
        </div>
      </div>
      <div class="clear"></div>
      <el-row style="margin-top:10px;">
        <el-button type="success" @click="excelExport">Excel导出</el-button>
      </el-row>
      <el-row style="margin-top:10px;">
          <el-table :data="list" 
          border stripe 
          :row-style="{height:'45px'}" 
          :cell-style='cellStyle' 
          :header-cell-style='rowClass'
          >
          <el-table-column label="用户id" prop="id"></el-table-column>
          <el-table-column label="用户头像" align="center">
              <template slot-scope="scope">
                <el-image v-if="scope.row.headimgurl" :src="scope.row.headimgurl" style="width: 30px;height:30px;"></el-image>
              </template>
            </el-table-column>
          <el-table-column label="用户昵称" prop="nickname"></el-table-column>
          <el-table-column label="手机号" prop="mobile_phone" width="108px"></el-table-column>
          <el-table-column label="宝宝性别" >
            <template slot-scope="scope">
              <span v-if="scope.row.boby_sex == 1">男</span>
              <span v-else-if="scope.row.boby_sex == 2">女</span>
            </template>
          </el-table-column>
          <el-table-column label="宝宝生日" prop="boby_birth" ></el-table-column>
          <el-table-column label="宝宝学年" prop="boby_school_age_string"></el-table-column>
          <el-table-column label="主要陪伴亲属" prop="boby_relatives_id_string"></el-table-column>
          <el-table-column label="是否借阅用户" prop="">
            <template slot-scope="scope">
              <span v-if="scope.row.is_borrow_user == 1">是</span>
              <span v-else>否</span>
            </template>
          </el-table-column>
          <el-table-column label="累计星星数量" prop="course_star"></el-table-column>
          <el-table-column label="累计完成课程" prop="course_complete"></el-table-column>
          <!-- <el-table-column label="来源" prop="source_string"></el-table-column> -->
          <!-- <el-table-column label="来源">
              <template slot-scope="scope">
              <span v-if="scope.row.source == 1">app</span>
              <span v-else-if="scope.row.source == 2">h5</span>
              <span v-else-if="scope.row.source == 3">购买链接</span>
            </template>
          </el-table-column> -->
          <el-table-column label="状态">
            <template slot-scope="scope" >
                <span  v-if="scope.row.status ==1">启用</span>
                <span v-else style="color:red">禁用</span>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="240px">
            <template slot-scope="scope" >
              <el-button type="text" size="mini" @click="jump(scope.row.id)" >购课记录</el-button>
              <el-button  type="text" slot="reference" size="mini" v-if="scope.row.status ==2" @click="changeSta(scope.row.id,scope.row.status)">启用</el-button>
              <el-button  type="text" slot="reference" size="mini" v-else @click="changeSta(scope.row.id,scope.row.status)">禁用</el-button>
            </template>
          </el-table-column>
        </el-table>

        <!--分页start-->
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="search.pagenum"
          :page-sizes="[10, 20, 30, 50]"
          :page-size="search.pagesize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total">
        </el-pagination>
        <!--分页end-->
      </el-row>
    </el-card>  
  </div>
</template>


<script>
import {list, changeStatus } from '@/api/interactUser'
export default {
 data (){
  return {
    list:[],
    search:{
      pagenum: 1,
      pagesize: 10,
      keyword:""
    },
    total: 0,
  }
 },
 created() {
    this.getUserList()
 },
 methods: {
  //-----------------------------tabel 文字居中-------------------------------
   rowClass({row, rowIndex}){
    return 'text-align:center'
  },
  cellStyle({row, column, rowIndex, columnIndex}){
    return 'text-align:center;padding:0px' 
  },
  //-------------------
  async getUserList(){
    const {data:res} = await list(this.search)
    if(res.status!=0){
      return this.$message.error(res.msg)
    }
    this.list = res.data.data
    this.total = res.data.total
  },
  //
  btnClick(){
    this.getUserList()
  },
  // 禁用启用
  async changeSta(id,status){
    const {data:res} = await changeStatus({user_id:id,status:status})
    if(res.status!=0){
      return this.$message.error(res.msg)
    }
    this.$message.success('ok')
    this.getUserList()
  },
  //----------------------------跳转: 购课记录-----------------------------
  jump(id){  
    this.$router.push({path:'/admin.courseuser.buyRecords',query:{user_id:id}})
  },
   // -------------------------分页-------------------------------------------
  handleSizeChange(newSize) {
    this.search.pagesize = newSize
    this.getUserList()
  },
  handleCurrentChange(newPage) {
    this.search.pagenum = newPage
    this.getUserList()
  },
  //-----------------------------excelExport------
  excelExport(){
      const headers = {
        '用户ID': 'id',
        '用户头像': 'headimgUrl',
        '用户昵称': 'nickname',
        '手机号': 'mobile_phone',
        '宝宝性别': 'boby_sex_string',
        '宝宝生日': 'boby_birth',
        '主要陪伴家属': 'boby_relatives_id_string',
        '是否借阅用户': 'is_borrow_user_string',
        '累计星星数量': 'course_star',
        '累计完成课程': 'course_complete',
        // '来源': 'source_string',
      }

      import('@/vendor/Export2Excel').then(async excel => {
        this.search.pagesize = this.total
        const {data:res} = await list(this.search)
        const data = this.formatJson(headers,res.data.data)
        excel.export_json_to_excel({
          header: Object.keys(headers), 
          data, 
          filename: '用户信息表', 
          autoWidth: true, 
          bookType: 'xlsx' 
        })
      })
    },
    formatJson(headers, rows) {
      console.log(rows)
      return rows.map(item => {
        return Object.keys(headers).map(key => {
          return item[headers[key]]
        })
      })
    },

 } 
}
</script>

<style scoped>
.nav_1{
  margin-bottom: 10px;
  float:left;
}
</style>