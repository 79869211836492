<!--用户报名信息-->
<template>
  <div>
    <el-card>
      <div class="nav_1">
        <div style="float:left;">
          <div style="float:left;margin-right:5px;height:40px;line-height:40px;">报名用户: </div>
          <div class="search"><el-input type="text" style="width:100%" v-model="addForm.keyword"  prefix-icon="el-icon-search"></el-input></div>
        </div>
      </div>
      <div class="clear"></div>
      <el-row style="margin-top:10px;">
        <el-button type="danger" style="width:100px;" @click="back">返回课表</el-button>
        <el-button type="success" style="width:100px;" @click="excelExport">Excel导出</el-button>
      </el-row>
      <el-row style="margin-top:10px;">
          <el-table :data="list" 
          border stripe 
          :row-style="{height:'45px'}" 
          :cell-style='cellStyle' 
          :header-cell-style='rowClass'
          >
          <el-table-column label="用户名" prop="user_name"></el-table-column>
          <el-table-column label="手机号" prop="user_mobile"></el-table-column>
          <el-table-column label="上课进度(已上/已解锁/总课程)" prop="p"></el-table-column>
          <el-table-column label="状态" prop="sign_status_string"></el-table-column>
          <el-table-column label="操作" width="240px">
            <template slot-scope="scope" >
              <el-button type="text" size="mini" @click="jump(scope.row)" v-if="scope.row.sign_status==1">上课记录</el-button>            
            </template>
          </el-table-column>
        </el-table>

      <!--分页start-->
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="addForm.pagenum"
        :page-sizes="[10, 20, 30, 50]"
        :page-size="addForm.pagesize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total">
      </el-pagination>
      <!--分页end--> 
      </el-row>
    </el-card>  
  </div>
</template>


<script>
import {getSignUpInfoList } from '@/api/courseSchoolTime'
export default {
 data (){
  return {
    bagTimeId: this.$route.query.bagTimeId, // 课表id
    search: this.$route.query.search,
    list:[],
    addDialogVisible: false,
    addForm:{
      keyword:'',
      bag_times_id: this.$route.query.bagTimeId,
      pagesize:10,
      pagenum:1,
    },
    total: 0,  
  }
 },
 created() {
    this.userSignUpList()
 },
 methods: {
  //-----------------------------tabel 文字居中-------------------------------
   rowClass({row, rowIndex}){
    return 'text-align:center'
  },
  cellStyle({row, column, rowIndex, columnIndex}){
    return 'text-align:center;padding:0px' 
  },
  //-------------------------------------------------------------------------
  addcp(){
    this.addDialogVisible = true
  },
  //-------------------列表----------
  async userSignUpList(){
    const {data:res} = await getSignUpInfoList(this.addForm)
    if(res.status!=0){
      return this.$message.error(res.msg)
    }
    this.list = this.formData(res.data.data)
    this.total = res.data.total

  },
  formData(data){
    const arr =[]
    for( let i in data){
       arr.push({
        app_user_id:data[i].app_user_id,
        bags_id:data[i].bags_id,
        progress:data[i].progress,
        sum:data[i].sum,
        user_name:data[i].user_name,
        user_mobile:data[i].user_mobile,
        p:data[i].progress+'/'+data[i].deblock+'/'+ data[i].sum,
        sign_status_string:data[i].sign_status_string,
        sign_status:data[i].sign_status,
        course_bags_cate:data[i].course_bags_cate,
        })
    }
    return arr
  },
  // -------------------------分页-------------------------------------------
  handleSizeChange(newSize) {
    this.addForm.pagesize = newSize
    this.userSignUpList(this.search)
  },
  handleCurrentChange(newPage) {
    this.addForm.pagenum = newPage
    this.userSignUpList(this.search)
  },
  //----------------------------跳转: 用户上课记录-----------------------------
  jump(row){  
    console.log(row)
    this.$router.push({path:'/admin.course.classRecord',query:{bags_id:row.bags_id , app_user_id:row.app_user_id,bagTimeId: this.$route.query.bagTimeId,course_bags_cate:row.course_bags_cate}})
  },
  back(){
    this.$router.push({path:'/admin.course.schooltt',query:{search:this.search}})
  },
  //-----------------------------导出--------------------------
  excelExport(){
    const headers = {
      '用户名': 'user_name',
      '手机号': 'user_mobile',
      '上课进度': 'p',
    }

    import('@/vendor/Export2Excel').then(async excel => {
      this.addForm.pagenum = 1
      this.addForm.pagesize = this.total
      const {data:res} = await getSignUpInfoList(this.addForm)
      const data = this.formatJson(headers,this.formData(res.data.data))

      excel.export_json_to_excel({
        header: Object.keys(headers), 
        data, 
        filename: '报名信息表', 
        autoWidth: true, 
        bookType: 'xlsx' 
      })
    })
  },
  formatJson(headers, rows) {
    return rows.map(item => {
      return Object.keys(headers).map(key => {
        return item[headers[key]]
      })
    })
  },


 } 
}
</script>

<style scoped>
.nav_1{
  margin-bottom: 10px;
  float:left;
}
.search{
  float:left;
  width:300px;
  margin-left:10px;
}
</style>