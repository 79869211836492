<template>
  <div>
    <el-form>
      <el-form-item label="展示形式">
         <el-select v-model="select_type" placeholder="">
          <el-option v-for="item in type" :label="item.name" :value="item.id" :key="item.id"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="结尾引导语音:">
        <el-upload
            class="avatar-uploader"
            :action="action"
            :show-file-list="false"
            :headers="token"
            :data="{dirname:'interactcourse',index:-3,mark:'audio'}"
            :on-success="handleAvatarSuccess"
            :http-request="(file)=> {uploadFile(file,0,0)}"
            >
          <div v-if="audio" style="width:178px;height:35px">{{ audio | removeDir| hidden }}</div>
          <div v-else style="width:178px;height:35px">上传</div>
        </el-upload>
      </el-form-item>
     
      <template v-if="select_type == 2">
      <div style="width:178px;">
        <el-form-item label="上传视频">
          <el-upload
              class="avatar-uploader"
              action="#"
              :show-file-list="false"
              :data="{dirname:'interactcourse',index:-4}"
              :http-request="(file)=> {uploadFile(file,2,0)}"
              >   
              <div v-if="video" style="width:178px;height:35px">{{ video | removeDir | hidden }}</div>        
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>
      </div>
      </template>
      <template v-else>
        <div>
        <p>绘本图片：<span style="color:red">（上传图片大小不要超过1M。点击图片可大图预览、拖拽可进行图片排序。）</span></p>
      </div>
      <div style="float:left">
        <div style="float: left; margin: 15px" v-for="(item,index) of items" :key="index"
         draggable="true"
         @dragstart="handleDragStart($event, item)"
         @dragover.prevent="handleDragOver($event, item)"
         @dragenter="handleDragEnter($event, item)"
         @dragend="handleDragEnd($event, item)"
        >
          <div class="container" >
            <img src="@/assets/common/delete.png" alt="" class="delete" @click="del(index)" />
            <el-image :src="imgDomain + item.img" :preview-src-list="srcList" style="width:178px;height:178px"></el-image>
            <div v-if="item.img" style="text-align:center;font-size:16px;">{{item.img| removeDir | hidden}}</div>
          </div>
          <div style="width:178px;margin-top:3px;">   
              <el-form-item>
              <el-upload class="avatar-uploader" 
              action="#"   
              :show-file-list="false" 
              :http-request="(file)=> {uploadFile(file,3,index)}"
              >
                <div v-if="item.audio" style="width:178px;height:35px">
                  {{ item.audio | removeDir | hidden }}
                </div>
                <div v-else style="width:178px;height:35px">上传音频+</div>
              </el-upload>
              </el-form-item>    
          </div>
            
        </div>
        <div style="float:left;margin-top: 17px">
          <el-form-item >
          <el-upload
              class="avatar-uploader"
              action="#"
              :show-file-list="false" 
              :http-request="(file)=> {uploadFile(file,1,0)}"
              multiple
              >
            <i  class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>
        </div>
      </div>
      </template>
               
    </el-form>
    <div class="clear"></div>
    <div style="position: relative; height: 40px">
      <el-button style="position: absolute; right: 90px" @click="back">取消</el-button>
      <el-button type="primary" style="position: absolute; right: 10px" @click="game2Click">确定</el-button>
    </div>
  </div>
</template>

<script>
import { imgUpload } from '@/api/upload'
import { contentAdd, contentDetail} from '@/api/course'
import {getQiniuToken} from '@/api/upload'
import * as qiniu from 'qiniu-js'
export default {
data() {
  return {
    courseId: this.$route.query.id,
    action: this.uploadUrl + '/admin/upload/upload',
    token: { token: window.sessionStorage.getItem("token") },
    gameType: 2,
    progressFlag:false,
    items: [],
    srcList:[],
    key:0,
    //--拖拽--
    ending: null,
    dragging: null,
    gragFlag: 0,
    imgListSort:[],
    //展示形式
    type:[{id:1,name:'图片'},{id:2,name:'视频'}],
    select_type:1,
    //结尾引导语音
    audio:'',
    //视频
    video:'',
  }
},
filters: {
  removeDir(value) {
    let site = value.lastIndexOf("\/");
    return value.substring(site+1, value.length)
  },
  hidden(value){
    let end = value.lastIndexOf("-")
    if(end>20){
      end = 20
    }
    return value.substring(0, end)
  },
},
created(){
  this.getContentDetail()
  this.changeFatherFlag()
},
methods: {
  // ----------改变父组件的值----------- 
   changeFatherFlag(){
     this.$emit('flagChange', false)
   },
  //------------- 
  back() {
    this.$router.push("/admin.course.index"); // 取消跳转回
     window.sessionStorage.setItem('courseTab',null)
  },
  handleRemove(file) {
    console.log(file);
  },
  
  handleDownload(file) {
    console.log(file);
  },
  //----------------------------------删除-------------------------------
  del(index) {
    this.items.splice(index,1)
    this.srcList.splice(index,1)
    window.sessionStorage.setItem('courseTab',2)
  },
  //-----------------------------------拖动-----------------------------
  handleDragStart(e, item) {
    this.dragging = item;
  },
  handleDragEnd(e, item) {
    console.log('拖动。。。。')
    if (this.ending.key === this.dragging.key) {
      return;
    }
    let newItems = [...this.items];
  
    const src = newItems.indexOf(this.dragging); 
    const dst = newItems.indexOf(this.ending);

    if(src>dst){
      newItems.splice(dst,0,newItems[src])
      newItems.splice(src+1,1)
    }else{
      item = newItems[src]
        newItems.splice(src,1)
        newItems.splice(dst,0,item)       
    }
    

    // newItems.splice(src, 1, ...newItems.splice(dst, 1, newItems[src]));
  
    this.items = newItems;
    this.$nextTick(() => {
      this.dragging = null;
      this.ending = null;
    });
    this.checkDragSort() //@x
  },
  checkDragSort(){
    for(let i=0;i<this.items.length;i++){
      if(this.items[i].key !=this.imgListSort[i]){
        this.gragFlag = 1
        break
      }
    }

    if(this.gragFlag == 1){
      window.sessionStorage.setItem('courseTab',2) 
    }else{
      window.sessionStorage.setItem('courseTab',null) 
    }
    this.gragFlag = 0
  },
  handleDragOver(e) {
    // 首先把div变成可以放置的元素，即重写dragenter/dragover
    // 在dragenter中针对放置目标来设置
    e.dataTransfer.dropEffect = "move";
  },
  handleDragEnter(e, item) {
    // 为需要移动的元素设置dragstart事件
    e.dataTransfer.effectAllowed = "move";
    this.ending = item;
  },
  //-------------------------上传-----------------------------
  handleAvatarSuccess(res, file) {
    // [{key:1},{key:2},{key:3}]
    if (res.status != 0) {
      return this.$message.error(res.msg);
    }
    console.log(res.data.origalName)
    this.progressFlag = false
    if(res.data.index == -1){
      if(res.data.url!=""){ // 上传成功，但是返回url是空

        if(this.items.length){
          let max =0
          let flag = 0
          for(let i in this.items){
            max = parseInt(i)
            if( !isNaN(parseInt(this.items[i].index)) && !isNaN(res.data.origalName) ) {
              if( parseInt(this.items[i].index)  > parseInt(res.data.origalName) ){ 
                 flag =1
                 break
              }
            }
          }

          if(flag == 0){
            max +=1
          }
        
          this.items.splice(max,0,{key:this.key,"img":res.data.url,"audio":'',index:res.data.origalName})
          this.srcList.splice(max,0,this.imgDomain+res.data.url)
          this.key++
          
        }else{
          this.items.push({key:this.key,"img":res.data.url,"audio":'',index:res.data.origalName})
          this.srcList.push(this.imgDomain+res.data.url)
          this.key++
        }


        // this.items.push({key:this.key,"img":res.data.url,"audio":''})
        // this.srcList.push(this.imgDomain+res.data.url)
        // this.key++
      }   
    }else if(res.data.index == -3){
      //引导语音
      this.audio = res.data.url
    }else if(res.data.index == -4){
      // 上传视频
      this.video = res.data.url
    }else{
      this.items[res.data.index].audio = res.data.url
    }
  
    window.sessionStorage.setItem('courseTab',2) 
  },
  //--------
  //--------
img_limit(val){
    let type = val.file.type
    let size = val.file.size
    let type_arr = type.split("/")
    let limit = 1   // 1M
    if(type_arr[0] == "image"){
      if(size> 1024*1024*limit ){
        return -1
      }
    }
    return 1
  },
  async uploadFile(uploadInfo,type,index){
    if(this.img_limit(uploadInfo) == -1){
      return this.$message.error("上传图片请小于1M")
    }

    const {data:token} = await getQiniuToken()
    const that = this
    const observer = {
        next(res){
          // console.log(res)
        },
        error(err){
          console.log(err)
        },
        complete(res){
          if(type == 0){ // 结尾引导语音
            that.audio = res.key
          }else if(type == 1){ // 图片
            
            if(that.items.length){
              let max =0
              let flag = 0
              let index =0
              if(that.getIndex(res.key) != -1){
                index = that.getIndex(res.key)
              }
              for(let i in that.items){
                max = parseInt(i)
                if( !isNaN(parseInt(that.items[i].index)) && !isNaN(index) ) {
                  if( parseInt(that.items[i].index)  > parseInt(index) ){ 
                    flag =1
                    break
                  }
                }
              }

              if(flag == 0){
                max +=1
              }
            
             
              that.items.splice(max,0,{key:that.key,"img":res.key,"audio":'',index:index})
              that.srcList.splice(max,0,that.imgDomain+res.key)
              that.key++
              
            }else{
              that.getIndex(res.key)
              let index =0
              if(that.getIndex(res.key) != -1){
                index = that.getIndex(res.key)
              }
              console.log("index",index)
              that.items.push({key:that.key,"img":res.key,"audio":'',index:index})
              that.srcList.push(that.imgDomain+ res.key )
              that.key++
            }

          }else if(type == 2) { // 视频
               that.video = res.key
          }else if(type == 3) { // 音频
               that.items[index].audio = res.key
          }
          window.sessionStorage.setItem('courseTab',2) 
        }
    }
   
    let name = uploadInfo.file.name
    let fileName = this.getFileName(name)
    
    if(parseInt(fileName) !=NaN){
      name = fileName+"-"+this.randStr(10)+"."+this.getExt(name)
    }
   
    const observable =  qiniu.upload(uploadInfo.file, "interactcourse/"+ name, token,{},{})
    const subscription =  observable.subscribe(observer)
  },
  randStr(e){
    let str = "abacdefghijklmn123456789QWERTYUIOPASDFGHJKLZXCVBNM"
    let n = "";
    for(let i=0;i<e;i++){
      n += str.charAt(Math.floor(Math.random()*str.length))
    }
    return n
  },
  getFileName(val){
    let end = val.lastIndexOf(".")
    return val.substring(0,end)
  },
  getExt(val){
    return val.substring(val.lastIndexOf(".")+1)
  },
  getIndex(key){
    let start = key.lastIndexOf("\/")
    let end = key.lastIndexOf("-")
    let str = key.substring(start+1, end)
    if(parseInt(str) == NaN){
      return -1
    }else{
      return parseInt(str)
    }
  },
  //----------------绘本阅读提交-------------
  async game2Click(){
    if(this.select_type == 1){
      if(this.items.length< 1){
        return this.$message.error('请上传图片')
      }
      
    }else{
      if(this.video == ""){
         return this.$message.error('请上传视频')
      }
    }

    if(this.audio == ""){
      return this.$message.error('请上传引导语音')
    }
    
    const {data:res} = await contentAdd({courseId:this.courseId, fileList:this.items, game_type:this.gameType,type:this.select_type,audio:this.audio,video:this.video})
    if(res.status!=0){
      return this.$message.error(res.msg)
    }
    window.sessionStorage.setItem('courseTabDefault',2) 
    this.$message.success(res.msg)
    this.getContentDetail()
    window.sessionStorage.setItem('courseTab',null) 
    
  },
  //--------------------------详情------------------------------------
  async getContentDetail() {
    const { data: res } = await contentDetail({
      courseId: this.$route.query.id,
      game_type: this.gameType,
    });
    
    if(res.data!=''){
      if(res.data.type == 1){
        this.select_type = 1
        this.items = res.data.items
        this.srcList = res.data.srcList.map((element) => {
          return this.imgDomain + element
        });

        for(let i=0;i<res.data.items.length;i++){
          this.imgListSort.push(res.data.items[i].key)
        }
      }else{
        this.select_type = 2
        this.video = res.data.video

      }
      this.audio = res.data.end_audio

    }
  }
}
}
</script>

<style scoped>
.clear {
  clear: both;
}
.container{
  position: relative;
}
.imgContent {
  width:178px;
  height: 178px;
  border: 1px solid black;
}
.delete {
  width:28px;
  height: 28px;
  color:red;
  position: absolute;
  right: 2px;
  z-index: 100;
}
.img{
  width:178px;
  height:178px;
}

.disabled .el-upload--picture-card {
  display: none;
}

.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>