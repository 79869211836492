import request from '@/utils/request'

//----------------------------------banner

export function list(data) {
  return request({
    url: '/admin/interactCourseBanner/list',
    method: 'post',
    data
  })
}

export function add(data) {
  return request({
    url: '/admin/interactCourseBanner/add',
    method: 'post',
    data
  })
}

export function detail(data) {
  return request({
    url: '/admin/interactCourseBanner/getBannerDetail',
    method: 'post',
    data
  })
}

export function edit(data) {
  return request({
    url: '/admin/interactCourseBanner/edit',
    method: 'post',
    data
  })
}
export function del(data) {
  return request({
    url: '/admin/interactCourseBanner/del',
    method: 'post',
    data
  })
}
export function changeStatus(data) {
  return request({
    url: '/admin/interactCourseBanner/changeStatus',
    method: 'post',
    data
  })
}