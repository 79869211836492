<template>
    <span>
        <div class="nav_1">
            <div class="div-left">
                <div class="d-order-no">状态: </div>
                <div class="div-left">
                    <el-select v-model="search.status" @change="selectStatus()">
                        <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                        </el-option>
                    </el-select>
                </div>
            </div>
        </div>
        <div class="clear"></div>
        <el-row style="margin-top:10px;">
            <el-button type="primary" style="width:100px;" @click="clickAddKfBtn()">添加客服</el-button>
        </el-row>
    </span>
</template>


<script>
export default {
    data() {
        return {
            options: [{
                value: '',
                label: '全部'
            }, {
                value: '1',
                label: '启用'
            }, {
                value: '2',
                label: '禁用'
            }],
            search: {
                status: '',
            },
        }
    },
    created() {
        console.log("123")
    },
    methods: {
        clickAddKfBtn() {
            this.$emit("open_add_kf")
        },
        selectStatus(value) {
            this.$emit('search', this.search)
        }
    }
}
</script>