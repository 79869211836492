import request from '@/utils/request'

//---------------------------------课程---------------------------------------------
//----------------------------------------------------------------------------------

// 课程列表
export function list(data) {
  return request({
    url: '/admin/interactCourse/list',
    method: 'post',
    data
  })
}

export function add(data) {
  return request({
    url: '/admin/interactCourse/add',
    method: 'post',
    data
  })
}

// 课程标签
export function getCourseTag(data) {
  return request({
    url: '/admin/interactCourse/getAllCourseTag',
    method: 'post',
    data
  })
}


export function edit(data) {
  return request({
    url: '/admin/interactCourse/edit',
    method: 'post',
    data
  })
}

// 禁用/启用
export function changeStatus(data) {
  return request({
    url: '/admin/interactCourse/changeStatus',
    method: 'post',
    data
  })
}

// 小结
export function getSummary(data) {
  return request({
    url: '/admin/interactCourse/getSummary',
    method: 'post',
    data
  })
}

// 
export function changeSummary(data) {
  return request({
    url: '/admin/interactCourse/changeSummary',
    method: 'post',
    data
  })
}


// 修改趣味问答 题目的状态
export function changeExerciseStatus(data) {
  return request({
    url: '/admin/InteractCourseGames/checkStatus',
    method: 'post',
    data
  })
}

export function del(data) {
  return request({
    url: '/admin/interactCourse/del',
    method: 'post',
    data
  })
}

export function gameCate() {
  return request({
    url: '/admin/interactCourse/getInteractCourseGameCate',
    method: 'post',
  })
}

// 课程内容详情
export function contentDetail(data) {
  return request({
    url: '/admin/interactCourseGames/getGamesDetail',
    method: 'post',
    data
  })
}

// 父组件
export function getStatut(data) {
  return request({
    url: '/admin/interactCourse/getStatut',
    method: 'post',
    data
  })
}


// 阅读课 课程内容添加
export function contentAdd(data) {
  return request({
    url: '/admin/interactCourseGames/contentAdd',
    method: 'post',
    data
  })
}

// 美术课 课程内容添加
export function contentAddV2(data) {
  return request({
    url: '/admin/interactCourseGames/contentAddAndEditV2',
    method: 'post',
    data
  })
}


//------------------------------------ 课程/课包(type 1/2) 分类-------------------------------
export function getcateList(data) {
  return request({
    url: '/admin/interactCourseTag/getList',
    method: 'post',
    data
  })
}

// 增
export function courseTagAdd(data) {
  return request({
    url: '/admin/interactCourseTag/add',
    method: 'post',
    data
  })
}

// 删
export function courseTagDel(data) {
  return request({
    url: '/admin/InteractCourseTag/del/'+data,
    method: 'delete',
  })
}

export function courseTagEdit(id,data) {
  return request({
    url: '/admin/InteractCourseTag/edit/'+id,
    method: 'post',
    data
  })
}


  