<!--课程订单-->
<template>
<div>
  <el-card>
    <div class="nav_1">
      <div class="div-left">
        <div class="d-order-no">订单编号: </div>
        <div  class="div-left">
          <el-input class="input"  v-model="search.orderNo"></el-input>
        </div>
      </div>
      <div class="div-left">
        <div class="d-order-no">课程包名称: </div>
        <div  class="div-left">
          <el-input class="input"  v-model="search.bagName"></el-input>
        </div>
      </div>
      <div class="div-left">
        <div class="d-order-no">购买用户: </div>
        <div  class="div-left">
          <el-input placeholder="支持搜索用户名或手机号" class="input"  v-model="search.userInfo"></el-input>
        </div>
      </div>
      <div class="div-left">
        <div class="d-order-no">客服: </div>
        <div  class="div-left">
          <el-input class="input"  v-model="search.customer"></el-input>
        </div>
      </div>
      <div class="div-left">
        <div class="d-order-no">售卖人: </div>
        <div  class="div-left">
          <el-input class="input"  v-model="search.salesman"></el-input>
        </div>
      </div>
    </div>
    <div class="nav_1">
      <div class="div-left">
        <div class="d-order-no">来源: </div>
        <div  class="div-left">
          <el-select v-model="search.source" placeholder="请选择" @change="selectSource(search.source)">
            <el-option v-for="(item,index) in source_arr" :label="item.name" :key="index" :value="item.id">{{item.name}}</el-option>    
          </el-select>
        </div>
      </div>
      <div  class="div-left">
        <!--日期start-->
        <div style="float:left;height:40px;line-height:40px;">购课时间: </div>
        <div style="float:left;margin-left:20px;">
          <div class="block">
            <el-date-picker
            @blur="timeBlur"
              v-model="value1"
              value-format="yyyy-MM-dd HH:mm:ss"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期">
            </el-date-picker>
          </div>
        </div>
        <!--日期end-->
      </div>
      <div class="div-left">
        <el-button  type="primary" icon="el-icon-search" @click="btnClick">搜索</el-button>
        <el-button  type="warning" icon="el-icon-refresh" @click="reset" >重置</el-button>
      </div>
    </div>
    <!---->
    <div class="clear"></div>
    <el-row style="margin-top:10px;">
      <el-button type="success" style="width:100px;" @click="excelExport">Excel导出</el-button>
    </el-row>
    <el-row style="margin-top:10px;">
      <el-table :data="orderList" 
        border stripe 
        :row-style="{height:'50px'}" 
        :cell-style='cellStyle' 
        :header-cell-style='rowClass'
        >
        <el-table-column label="订单号" prop="order_no" width="190px"></el-table-column>
        <el-table-column label="用户名" prop="user_name" width="140px"></el-table-column>
        <el-table-column label="手机号" prop="user_mobile" width="150px"></el-table-column>
        <el-table-column label="课程包名称" prop="bags_title"></el-table-column>
        <el-table-column label="购课金额(元)" prop="pay_price" width="110px"></el-table-column>
        <el-table-column label="购课时间" prop="pay_time" width="160px"></el-table-column>
        <el-table-column label="购课方式" width="80px">
          <template slot-scope="scope" >
              <span  v-if="scope.row.pay_type ==1">免费领取</span>
              <span v-else-if="scope.row.pay_type ==2">微信</span>
              <span v-else-if="scope.row.pay_type ==3">支付宝</span>
              <span v-else-if="scope.row.pay_type ==4">小程序</span>
          </template>
        </el-table-column>
        <el-table-column label="支付状态" width="80px">
          <template slot-scope="scope" >
              <span  v-if="scope.row.order_status ==0">待支付</span>
              <span v-else-if="scope.row.order_status ==1">已支付</span>
              <span v-else-if="scope.row.order_status ==2">已取消</span>
              <span v-else-if="scope.row.order_status ==3">已退款</span>
              <span v-else-if="scope.row.order_status ==4">退款处理中</span>
          </template>
        </el-table-column>
        <el-table-column label="客服" prop="coustomer" width="120px"></el-table-column>
        <el-table-column label="售卖人" prop="salesman" width="120px"></el-table-column>
        <el-table-column label="来源" width="90px">
          <template slot-scope="scope" >
            <span v-if="scope.row.source == 1">app内购买</span>
            <span v-else-if="scope.row.source == 2">链接购买</span>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="140px">
          <template slot-scope="scope">
            <el-button type="text" size="mini" @click="refund(scope.row.id)" v-if="scope.row.order_status == 1">退款</el-button>
            <el-button type="text" size="mini" @click="showLog(scope.row.id)" v-if="scope.row.order_status ==3 || scope.row.order_status ==4" >退款日志</el-button>      
            <el-button type="text"  v-if="scope.row.is_hidden==0" size="mini" @click="hidden(scope.row.id)">隐藏</el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-row>

    <!--分页start-->
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="search.pagenum"
      :page-sizes="[10, 20, 30, 50]"
      :page-size="search.pagesize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total">
    </el-pagination>
    <!--分页end-->

    <!--退款服务 start-->
    <el-dialog title="退款服务" :close-on-click-modal="false" :visible.sync="addDialogVisible" width="40%" @close="addDialogClosed">
      <div style="margin-bottom:20px;">
        <h4 style="color:red">*退款或退课后，用户购买的课程无法再学习，且无法恢复，请确认后再进行退款操作</h4>
      </div>
      <el-form ref="addFormRef"  label-width="100px">
        <el-form-item label="*处理内容:">
          <div >
            <div>
              <el-radio :label="1" v-model="refund_form.content_type">退款退课 </el-radio>
            </div>
            <div>
              <el-radio :label="2" v-model="refund_form.content_type">仅退课</el-radio>
            </div>
            <div style="float: left;height:40px;">
              <div style="float: left;">
                <el-radio  :label="3" v-model="refund_form.content_type">部分退款</el-radio>
              </div>
              <div style="float: left;margin-left:10px;">
                <el-input  type="number" v-model="refund_form.refund_price"></el-input>
              </div>
            </div>
          </div>
        </el-form-item>
        <el-form-item label="理由/备注:">
          <el-input type="textarea" v-model="refund_form.reason"></el-input>
        </el-form-item>       
        <el-form-item label="*操作密码:">
          <el-input v-model="refund_form.refund_pass" type="password"></el-input>
        </el-form-item>       
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addDialogVisible = false">取消</el-button>
        <el-button type="primary" @click="addSubmit">确认退款</el-button>
      </span>
    </el-dialog>
    <!--退款服务 end-->

    <!---->
    <el-dialog
      title="退款日志"
      :visible.sync="logDialogVisible"
      width="60%"
      >
      <el-table
        :data="record"
        stripe
        style="width: 100%">
        <el-table-column label="处理内容" prop="status_msg">
        </el-table-column>
        <el-table-column
          prop="reason"
          label="理由/备注"
          >
        </el-table-column>
        <el-table-column
          prop="created_at"
          label="操作时间" width="180">
        </el-table-column>
        <el-table-column
          prop="account_name"
          label="操作人">
        </el-table-column>
      </el-table>
      <span slot="footer" class="dialog-footer">
        <el-button @click="logDialogVisible = false">关闭</el-button>
      </span>
    </el-dialog>

  </el-card>
</div>
</template>

<script>
import {list,refundApi,refundRecord,orderHidden} from '@/api/courseOrder'
export default {
 data (){
  return {
    orderList:[],
    total: 0,
    search: {
      pagenum: 1,
      pagesize: 10,
      orderNo: '',
      bagName: '',
      userInfo: '',
      startTime: '',
      endTime: '',
      customer:"",
      source:"",
      salesman:"",
    }, // this.search.customer_source = 1
    source_arr:[{id:0,name:"全部"},{id:1,name:"app内购买"},{id:2,name:"链接购买"}],
    //---------------时间--------------------
    pickerOptions: {
      shortcuts: [{
        text: '最近一周',
        onClick(picker) {
          const end = new Date();
          const start = new Date();
          start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
          picker.$emit('pick', [start, end]);
        }
      }, {
        text: '最近一个月',
        onClick(picker) {
          const end = new Date();
          const start = new Date();
          start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
          picker.$emit('pick', [start, end]);
        }
      }, {
        text: '最近三个月',
        onClick(picker) {
          const end = new Date();
          const start = new Date();
          start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
          picker.$emit('pick', [start, end]);
        }
      }]
    },
    value1: '',
    //--------------退款-------------
    refund_form :{
      order_id:"",
      content_type: 1,
      reason:"",
      refund_pass:"",
      refund_price:""
    },
    //-------------------------------
    addDialogVisible: false,
    logDialogVisible: false,
    //----------日志-----------
    record:[],
  }
 },
 created(){
  this.getOrderList()
 },
 watch: {
    value1(newData) {
      if(newData == null){
        this.value1 = ''
        this.search.startTime = ''
        this.search.endTime = ''
      }    
    }
  },
 methods: {
  //-----------------------------tabel 文字居中-------------------------------
  rowClass({row, rowIndex}){
    return 'text-align:center'
  },
  cellStyle({row, column, rowIndex, columnIndex}){
    return 'text-align:center;padding:0px' 
  },
  //-------------------------------订单列表-----------------------------------
  async getOrderList(){
    const {data:res} = await list(this.search)
    if(res.status!=0){
      return this.$message.error(res.msg)
    }
    this.orderList = res.data.data
    this.total = res.data.total
  },
  async hidden(id){
    // 订单隐藏
    const {data:res} = await orderHidden({id:id})
    if(res.status!=0){
      return this.$message.error(res.msg)
    }
    this.$message.success("已隐藏")
    this.getOrderList();
  },
  // -------------------------分页-------------------------------------------
  handleSizeChange(newSize) {
    this.search.pagesize = newSize
    this.getOrderList(this.search)
  },
  handleCurrentChange(newPage) {
    this.search.pagenum = newPage
    this.getOrderList(this.search)
  },
  //------------------------------筛选---------------------------------------
  timeBlur(){
    this.search.pagenum = 1
    this.search.pagesize = 10
    this.search.startTime = this.value1[0]
    this.search.endTime = this.value1[1]
  },
  selectSource(val){
    this.search.pagenum = 1
    this.search.pagesize = 10
    this.search.source = val
  },
  btnClick(){
    this.search.pagenum = 1
    this.search.pagesize = 10
    this.getOrderList()
  },
  reset(){
    this.search.orderNo = ""
    this.search.bagName = ""
    this.search.userInfo = ""
    this.search.customer = ""
    this.search.source = ""
    this.search.startTime = ""
    this.search.endTime = ""
    this.search.salesman = ""
    this.value1 = ""
    this.getOrderList()
  },
  //-----------------------------导出--------------------------
  excelExport(){
    const headers = {
      '订单号': 'order_no',
      '用户名': 'user_name',
      '手机号': 'user_mobile',
      '课程包名称': 'bags_title',
      '购课金额(元)': 'pay_price',
      '购课时间': 'pay_time',
      '购课方式': 'pay_type',
      '支付状态': "order_status",
      '客服': "coustomer",
      '售卖人': "salesman",
      '来源': "source_string",
    }

    import('@/vendor/Export2Excel').then(async excel => {
      this.search.pagenum = 1
      this.search.pagesize = this.total
      const {data:res} = await list(this.search)
      const data = this.formatJson(headers,res.data.data)
      excel.export_json_to_excel({
        header: Object.keys(headers), 
        data, 
        filename: '课程订单表', 
        autoWidth: true, 
        bookType: 'xlsx' 
      })
    })
  },
  formatJson(headers, rows) {
    return rows.map(item => {
      return Object.keys(headers).map(key => {
        if(headers[key]=="pay_type"){
          switch (item[headers[key]]){
            case 1:
              return "免费领取"
              break
            case 2:
              return "微信"  
              break
            case 3:
              return "支付宝"
              break
            case 4:
              return "小程序"
              break
          }
        }
        if(headers[key]=="order_status"){
          switch (item[headers[key]]){
            case 0:
              return "待支付"
              break
            case 1:
              return "已支付"
              break
            case 2:
              return "已取消"  
              break
            case 3:
              return "已退款"
              break
            case 4:
              return "退款处理中"
              break
          }
        }
        return item[headers[key]]
      })
    })
  },
  //-----------------------------------
  //-------------------退款----------------
  refund(id){
    this.addDialogVisible = true
    this.refund_form.order_id = id
  },
  addDialogClosed(){
    this.refund_form.order_id=""
    this.refund_form.content_type=1
    this.refund_form.reason=""
    this.refund_form.refund_pass=""
    this.refund_form.refund_price=""
  },
  async addSubmit(){
    if(this.refund_form.content_type == 3){
      if(this.refund_form.refund_price==""){
        return this.$message.error("请填写退款金额")
      }
    }
    const {data:res} = await refundApi(this.refund_form)
    if(res.status!=0){
      return this.$message.error(res.msg)
    }
    this.addDialogVisible = false
    this.$message.success(res.msg)
    this.getOrderList()
  },
  //--------日志
  async showLog(id){
    this.logDialogVisible = true
    const {data:res} = await refundRecord({order_id:id})
    if(res.status!=0){
      return this.$message.error(res.msg)
    }
    this.record = res.data.list
  },
 }
}
</script>


<style scoped>
.nav_1{
  margin-bottom: 10px;
  float:left;
}
.div-left{
  float:left;
  margin-right: 10px;
}

.input{
  width: 220px;
}

.d-order-no{
  float:left;
  margin-right:15px;
  height:35px;
  line-height:35px;
}

</style>