import request from '@/utils/request'

//-------------------------消息-------------------------------
//---------------------------------------------------------------


export function list(data) {
  return request({
    url: "/admin/message/list",
    method: "post",
    params: data,
  })
}

export function changeStatus(data) {
  return request({
    url: "/admin/message/changeStatus",
    method: "post",
    params: data,
  })
}


export function sendRecords(data) {
  return request({
    url: "/admin/message/sendRecords",
    method: "post",
    params: data,
  })
}