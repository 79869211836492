<template>
  <div>
    <upload :title="upload.title" :type="upload.type" @getUrl="getUrl"></upload>
  </div>
</template>


<script>
import upload from '@/components/Upload'
export default {
  components:{
    upload
  },
  data(){
    return{
      upload:{
        title:"吃饭不洗碗:",
        type: "img",
      },
      url:"",
    }
  },
  methods:{
    getUrl(url){
      this.url = url
      console.log("url",url)
    }
  }
}
</script>

<style scoped>

</style>