<template>
    <div>
      <el-card>
        <el-row style="margin-top:10px;">
          <el-button type="primary" v-if="checkPermission('admin.course.add')" @click="addCourseClick">添加课程</el-button>
        </el-row>
      </el-card>  

      <!--添加课程start-->
      <el-dialog title="添加课程" :close-on-click-modal="false" :visible.sync="addDialogVisible" width="40%">
        <el-form ref="addFormRef" :model="addForm" label-width="100px;">
          <div style="width:178px;">
            <el-form-item label="课程封面"  >
              <el-upload
                class="avatar-uploader"
                action=""
                :show-file-list="false"   
                :http-request="(file)=> {uploadFile(file)}"
                multiple
                >   
                <img v-if="addForm.img" :src="imgDomain+addForm.img" class="avatar">
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
              <p style="color:red;font-size:8px;height:10px;margin-top:-20px;text-align:center">图片大小不要超过1M</p>
              <el-progress type="circle" v-if="show" :percentage="percentage" class="progress"></el-progress>
            </el-form-item>
          </div>    
        </el-form>
      </el-dialog>
      <!--添加课程end-->

    </div>
</template>

<script>
import {getQiniuToken} from '@/api/upload'
import * as qiniu from 'qiniu-js'
export default {
 data (){
    return {
        action: this.uploadUrl + this.uploadApi,
        token: { token: window.sessionStorage.getItem("token") },
        //--------------------添加
        addForm: {  
          img: '', 
        },
        dialogImageUrl: '',
        dialogVisible: false,  
        addDialogVisible:false,
        percentage:0,
        show:false,
    }
 },
 created() {
    
 },
 methods: {  
  addCourseClick(){
    this.addDialogVisible = true
  },
  //---------------------------------------------------------图片----------------------------------------------
  handleAvatarSuccess(res, file) {  // 图片上传成功
    this.checkUpload(res.msg)
    if(res.status != 0){
      this.progressFlag = false
      return this.$message.error(res.msg)
    }
    this.progressFlag = false
    this.addForm.img =  res.data.url
    this.editForm.img = res.data.url      
  }, 
   async uploadFile(uploadInfo){
    const {data:token} = await getQiniuToken()
    const that = this
    const observer = {
        next(res){
          console.log('next:', res)
          that.percentage = parseInt(res.total.percent.toString().split(".")[0])
        },
        error(err){
          console.log(err)
        },
        complete(res){
          console.log('complete:',res)
          that.addForm.img = res.key
          that.show = false    
        }
    }
    this.show = true
    const observable =  qiniu.upload(uploadInfo.file, "interactcourse/"+uploadInfo.file.name, token,{},{})
    const subscription =  observable.subscribe(observer)
  },   
 }
}
</script>


<style scoped>
 .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }
  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }
  .loadingModal {
  width: 100%;
  margin: 0 auto;
  background: rgba(22, 21, 21, 0.3);
  text-align: center;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 999999;
}

.progress{
 position: absolute;
 margin-left:26px;
 margin-top:-147px;
}
</style>