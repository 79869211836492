<template>
    <div>
        <el-row style="margin-top:10px;">
            <el-table :data="customerList" border stripe :row-style="{ height: '50px' }" :cell-style='cellStyle'
                :header-cell-style='rowClass'>
                <el-table-column label="ID" prop="id"></el-table-column>
                <el-table-column label="客服姓名" prop="name"></el-table-column>
                <el-table-column label="企业微信号" prop="work_wechat_no"></el-table-column>
                <el-table-column label="企微二维码" prop="bags_title">
                    <template slot-scope="scope">
                        <el-button type="text" v-if="scope.row.work_wechat_qrcode" @click="showPic(imgDomain + scope.row.work_wechat_qrcode)" size="mini">查看
                        </el-button>
                    </template>
                </el-table-column>
                <el-table-column label="企微绑定手机号" prop="mobile_phone"></el-table-column>
                <el-table-column label="所属职位" prop="customer_type"></el-table-column>
                <el-table-column label="状态" prop="status">
                    <template slot-scope="scope">
                        <span v-if="scope.row.status == 1">正常</span>
                        <span v-else>禁用</span>
                    </template>
                </el-table-column>
                <el-table-column label="操作" width="140px">
                    <template slot-scope="scope">
                        <span v-if="scope.row.status == 1">
                            <el-button type="text" @click="changeStatus(scope.row.id)" size="mini">禁用</el-button>
                            <template>
                                <el-button type="text" @click="deleteKf(scope.row.id)" size="mini">删除</el-button>
                            </template>
                        </span>
                        <span v-if="scope.row.status == 2">
                            <el-button type="text" @click="changeStatus(scope.row.id)" size="mini">启用</el-button>
                            <template>
                                <el-button type="text" @click="deleteKf(scope.row.id)" size="mini">删除</el-button>
                            </template>
                        </span>
                        <el-button  style="margin-left:5px" type="text" @click="editShow(scope.row)" size="mini">编辑</el-button> 
                    </template>
                </el-table-column>
            </el-table>
        </el-row>

        <!--分页start-->
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
            :current-page="search.pagenum" :page-sizes="[10, 20, 30, 50]" :page-size="search.pagesize"
            layout="total, sizes, prev, pager, next, jumper" :total="total">
        </el-pagination>
        <!--分页end-->

        <el-dialog title="编辑客服" :close-on-click-modal="false" :visible.sync="editDialogVisible" width="30%" @close="editDialogClosed">
            <el-form ref="addFormRef" label-width="100px">
                <el-form-item label="所属职位:">
                  <el-checkbox-group v-model="customerData.position" >
                    <el-checkbox
                        v-for="item in position"
                        :label="item.id"
                        :key="item.id"
                        >{{ item.name }}</el-checkbox
                    >
                    </el-checkbox-group>  
                </el-form-item>
                <el-form-item label="客服姓名:">
                    <el-input v-model="customerData.name"></el-input>
                </el-form-item>
                <el-form-item label="客服微信号:">
                    <el-input v-model="customerData.work_wechat_no"></el-input><span style="color:red">*若勾选客服，必须添加与企微对应的微信号</span>
                </el-form-item>
                <el-form-item label="客服手机号:">
                    <el-input v-model="customerData.mobile_phone"></el-input><span style="color:red">*若勾选客服，必须添加与企微对应的手机号</span>
                </el-form-item>
                <div style="width:178px;">
                    <el-form-item label="微信二维码:">
                        <el-upload class="avatar-uploader" action="#" :show-file-list="false" :http-request="(file) => {uploadFile(file,1)}">
                            <img v-if="customerData.work_wechat_qrcode"
                                :src="imgDomain + customerData.work_wechat_qrcode" class="avatar">
                            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                        </el-upload>
                    </el-form-item>
                </div>
                <div style="margin-left:90px"><span style="color:red">*若勾选客服，必须添加微信二维码</span></div>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="editDialogVisible = false">取消</el-button>
                <el-button type="primary" @click="editSubmit">保存</el-button>
            </span>
        </el-dialog>

    </div>

</template>

<script>
import 'viewerjs/dist/viewer.css'
import { getCustomerList, deleteCustomer, changeCustomerStatus } from '@/api/customer'
import { updateCustomer } from '@/api/customer'
import {getQiniuToken} from '@/api/upload'
import * as qiniu from 'qiniu-js'

export default {
    data() {
        return {
            customerList: [],
            total: 0,
            search: {
                pagenum: 1,
                pagesize: 10,
                status: '',
            },
            position:[{id:1,name:"课程客服"},{id:3,name:"销售"},{id:2,name:"联系客服"}],
            editDialogVisible:false,
            customerData: {
                name: "",
                mobile_phone: "",
                work_wechat_no: "",
                work_wechat_qrcode: "",
                position:[],
                customer_type:""
            },
        }
    },
    created(){
       
    },
    methods: {
        showPic(url) {
            this.imagesUrl = [];
            this.imagesUrl.push(url)
            this.$viewerApi({
                images: this.imagesUrl,
            })
        },
        async getCustomerListApi(query) {
            const { data: res } = await getCustomerList(this.search)
            if (res.status != 0) {
                return this.$message.error(res.msg)
            }
            this.customerList = this.formData(res.data.data)
            this.total = res.data.total
        },
        formData(data){
            const arr =[]
            for( let i in data){
            let customer_type ="" 
            let customer_type_origin = data[i].customer_type
            let customer_arr = data[i].customer_type.split(",") 
            for(let i in customer_arr){
                let  temp = this.position.filter((val)=>{
                    if(val.id == customer_arr[i]){
                        return val.name
                    }
                })
                if(temp){
                    if(temp[0].name == "销售"){
                        customer_type += ","+temp[0].name
                    }else{
                        customer_type = ","+temp[0].name+customer_type
                    }
                    
                }
            } 

            arr.push({
                id:data[i].id,
                mobile_phone:data[i].mobile_phone,
                name:data[i].name,
                status:data[i].status,
                work_wechat_no:data[i].work_wechat_no,
                work_wechat_qrcode:data[i].work_wechat_qrcode,
                work_wechat_userid:data[i].work_wechat_userid,
                customer_type:customer_type.substring(1),
                customer_type_origin:customer_type_origin
                })
            }
            return arr
        },
        rowClass({ row, rowIndex }) {
            return 'text-align:center'
        },
        cellStyle({ row, column, rowIndex, columnIndex }) {
            return 'text-align:center;padding:0px'
        },
        handleSizeChange(newSize) {
            this.search.pagesize = newSize
            this.getCustomerListApi();
        },
        handleCurrentChange(newPage) {
            this.search.pagenum = newPage
            this.getCustomerListApi();
        },
        changeStatus(id) {
            const self = this;
            changeCustomerStatus({ id: id }).then((res) => {
                let code = res.data.status;
                if (code === 0) {
                    self.$message.success("操作成功")
                    self.getCustomerListApi({})
                } else {
                    self.$message.error(res.data.msg)
                }
            })
        },
        deleteKf(id) {
            const self = this;
            this.$confirm('确认要删除嘛', '提示', {
                confirmButtonText: '确认',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(async () => {
                const { data: res } = await deleteCustomer({ id: id })
                if (res.status != 0) {
                    return this.$message.error(res.msg)
                }
                this.search.pagenum = 1
                this.getCustomerListApi()
                this.$message.success('删除成功')
            }).catch(()=>{
                
            }) 
        },
        //----------------编辑-------
        editShow(row){
            this.editDialogVisible = true
            let customer_arr = row.customer_type_origin.split(",")
            customer_arr.map((el)=>{
                this.customerData.position.push(parseInt(el))
            })
            this.customerData.id = row.id
            this.customerData.name = row.name
            this.customerData.work_wechat_no = row.work_wechat_no
            this.customerData.mobile_phone = row.mobile_phone
            this.customerData.work_wechat_qrcode = row.work_wechat_qrcode
        },
        editDialogClosed(){
            this.clear()
        },
        editSubmit() {
            const self = this;
            let flag = false
            for(let i in this.customerData.position){
                if(this.customerData.position[i] == 1){
                    flag = 1
                }
            }
            if(this.customerData.position.length<1){
                return this.$message.error("请选择所属职位!")
            }

            if (this.customerData.name == "" || this.customerData.name == undefined) {
                this.$message.error("请输入客服名称")
                return
            }

            if (this.customerData.mobile_phone == "" || this.customerData.mobile_phone == undefined) {
                this.$message.error("请输入手机号")
                return
            }
                           
            if(flag == 1){

                 if (this.customerData.work_wechat_no == "" || this.customerData.work_wechat_no == undefined) {
                    this.$message.error("请输入客服微信号")
                    return
                }
                
                if (this.customerData.work_wechat_qrcode == "" || this.customerData.work_wechat_qrcode == undefined) {
                    this.$message.error("请上传企微二维码")
                    return
                }
            }

            this.customerData.customer_type = this.customerData.position.toString()    
            updateCustomer(this.customerData).then((res) => {
                let code = res.data.status;
                if (code === 0) {
                    self.$message.success("编辑客服成功")
                    self.editDialogVisible = false;
                    this.getCustomerListApi()
                    self.clear()
                } else {
                    self.$message.error(res.data.msg)
                }
            })
        },
         clear(){
          this.customerData.name = ""  
          this.customerData.mobile_phone = ""  
          this.customerData.work_wechat_no = ""  
          this.customerData.work_wechat_qrcode = ""
          this.customerData.position = []  
        },
        async uploadFile(uploadInfo,index){   
            const {data:token} =  await getQiniuToken()
            const that = this
            const observer = {
                next(res){
                console.log('next:', res)
                },
                error(err){
                console.log(err)
                },
                complete(res){
                    console.log('complete:....')
                    that.customerData.work_wechat_qrcode = res.key
                }
            }
           
            let name = uploadInfo.file.name
            let fileName = this.getFileName(name)
            name = this.randStr(15)+"."+this.getExt(name)
          
            const observable =  qiniu.upload(uploadInfo.file, "interactcourse/"+ name,token,{},{})
            const subscription =  observable.subscribe(observer)
        },
        randStr(e){
            let str = "abacdefghijklmn123456789QWERTYUIOPASDFGHJKLZXCVBNMzxcvbnmqwertyuiopasdfghjkl"
            let n = "";
            for(let i=0;i<e;i++){
                n += str.charAt(Math.floor(Math.random()*str.length))
            }
            return n
        },
        getFileName(val){
            let end = val.lastIndexOf(".")
            return val.substring(0,end)
        },
        getExt(val){
            return val.substring(val.lastIndexOf(".")+1)
        },
    }
}
</script>