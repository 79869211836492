<!--查看日数据-->
<template>
<div>
  <el-card>
    <div class="nav_1">
      <!-- <div class="div-left">
        <div class="d-order-no">课程包名称: </div>
        <div  class="div-left">
          <el-input class="input" v-model="search.keyword" @blur="Blur"></el-input>
        </div>
      </div> -->
    </div>
    <!---->
    <div class="clear"></div>
    <el-row style="margin-top:10px;">
      <el-button type="success" style="width:100px;" @click="excelExport">Excel导出</el-button>
    </el-row>
    <el-row style="margin:7px 0">
      <h2>{{name}}</h2>
    </el-row>
    <el-row style="margin-top:10px;">
      <el-table :data="list" 
        border stripe 
        :row-style="{height:'50px'}" 
        :cell-style='cellStyle' 
        :header-cell-style='rowClass'
        >
        <el-table-column label="日期" prop="add_date"></el-table-column>
        <el-table-column label="查看课包次数" >
          <template slot-scope="scope">
            <el-button type="text"  size="mini" @click="show(scope.row.add_date,1)">{{scope.row.course_bags_click_num}}</el-button>
          </template>
        </el-table-column>
        <el-table-column label="查看课包人数" >
          <template slot-scope="scope">
            <el-button type="text"  size="mini" @click="show(scope.row.add_date,2)">{{scope.row.course_bags_people_num}}</el-button>
          </template>
        </el-table-column>
        <el-table-column label="点击购买次数" >
          <template slot-scope="scope">
            <el-button type="text"  size="mini" @click="show(scope.row.add_date,3)">{{scope.row.course_bags_buy_click_num}}</el-button>
          </template>
        </el-table-column>
        <el-table-column label="点击购买人数">
           <template slot-scope="scope">
            <el-button type="text"  size="mini" @click="show(scope.row.add_date,4)">{{scope.row.course_bags_buy_people_num}}</el-button>
          </template>
        </el-table-column>
        <el-table-column label="购买数量">
          <template slot-scope="scope">
            <el-button type="text"  size="mini" @click="show(scope.row.add_date,5)">{{scope.row.course_bags_pay_num}}</el-button>
          </template>
        </el-table-column>
        <el-table-column label="退款数量">
          <template slot-scope="scope">
            <el-button type="text"  size="mini" @click="show(scope.row.add_date,6)">{{scope.row.course_bags_refund_num}}</el-button>
          </template>
        </el-table-column>  
      </el-table>
    </el-row>

    <!--分页start-->
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="search.pagenum"
      :page-sizes="[10, 20, 30, 50]"
      :page-size="search.pagesize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total">
    </el-pagination>
    <!--分页end-->

  </el-card>

  <!--数据明细 start-->
      <el-dialog title="数据明细" :close-on-click-modal="false" :visible.sync="addDialogVisible" width="40%" @close="addDialogClosed">
        <el-row style="margin-top:10px;">
          <el-table :data="detail" 
            border stripe 
            :row-style="{height:'50px'}" 
            :cell-style='cellStyle' 
            :header-cell-style='rowClass'
            >
            <el-table-column label="用户昵称" prop="user_name"></el-table-column>
            <el-table-column label="用户手机号" prop="mobile"></el-table-column>
            <el-table-column label="操作时间" prop="created_at"></el-table-column>  
          </el-table>
        </el-row>
        <span slot="footer" class="dialog-footer">
            <el-button type="primary" @click="addDialogVisible = false">确定</el-button>
        </span>
      </el-dialog>
      <!--数据明细 end-->


</div>
</template>

<script>
import { getStatisticsDay, getStatisticsLogs } from '@/api/static'

export default {
 data (){
  return {
    list:[],
    total: 0,
    name: this.$route.query.course_bag_name,
    search: {
      bags_id: this.$route.query.bags_id,
      pagenum:1,
      pagesize:10
    },
    addDialogVisible: false,
    detail:[],
  }
 },
 created(){
  this.getList()
 },
 methods: {
  //-----------------------------tabel 文字居中-------------------------------
  rowClass({row, rowIndex}){
    return 'text-align:center'
  },
  cellStyle({row, column, rowIndex, columnIndex}){
    return 'text-align:center;padding:0px' 
  },
  //-------------------------------列表-----------------------------------
  async getList(){
    const {data:res} = await getStatisticsDay(this.search)
    if(res.status!=0){
      return this.$message.error(res.msg)
    }
    this.list = res.data.data
    this.total = res.data.total
  },
  // -------------------------分页-------------------------------------------
  handleSizeChange(newSize) {
    this.search.pagesize = newSize
    this.getList()
  },
  handleCurrentChange(newPage) {
    this.search.pagenum = newPage
    this.getList()
  },
  // 
  async changeSta(id,status){
    const {data:res} = await changeStatus({mes_id:id,status:status})
    if(res.status!=0){
      return this.$message.error(res.msg)
    }
    this.$message.success('ok')
    this.getList()
  },
  //-------------------数据明细---------------------
  async show(date,type){
    const {data:res} = await getStatisticsLogs({bags_id:this.search.bags_id,date:date,type:type})
    if(res.status!=0){
      return this.$message.error(res.msg)
    }
    this.detail = res.data
    this.addDialogVisible = true
  },
  addDialogClosed(){

  },
  //-----------------------------excelExport------
  excelExport(){
      const headers = {
        '日期': 'add_date',
        '查看课包次数': 'course_bags_click_num',
        '查看课包人数': 'course_bags_people_num',
        '点击购买次数': 'course_bags_buy_click_num',
        '点击购买人数': 'course_bags_buy_people_num',
        '购买数量': 'course_bags_pay_num',
        '退款数量': 'course_bags_refund_num',
      }

      import('@/vendor/Export2Excel').then(async excel => {
        this.search.pagesize = this.total
        const {data:res} = await getStatisticsDay(this.search)
        const data = this.formatJson(headers,res.data.data)
        excel.export_json_to_excel({
          header: Object.keys(headers), 
          data, 
          filename: '日数据', 
          autoWidth: true, 
          bookType: 'xlsx' 
        })
      })
    },
    formatJson(headers, rows) {
    return rows.map(item => {
      return Object.keys(headers).map(key => {
        return item[headers[key]]
      })
    })
  },

 }
}
</script>


<style scoped>
.nav_1{
  margin-bottom: 10px;
  float:left;
}
.div-left{
  float:left;
  margin-right: 10px;
}

.input{
  width: 220px;
}

.d-order-no{
  float:left;
  margin-right:15px;
  height:35px;
  line-height:35px;
}

</style>